/* eslint-disable array-callback-return */
// @ts-nocheck
import moment from 'moment';
import constants from './Const';

// eslint-disable-next-line no-unused-vars
const analystDate = (row, event) => {
  const roles = Object.keys(row);
  const myArr = roles.map((item) => {
    return item.split('_');
  });
  switch (event) {
    case 'search_id':
      return myArr.map((item) => {
        if (item[0] === 'search' && item[1] === 'id') {
          return row?.search_id;
        }
        if (item[0] === 'search') {
          return row?.search_id;
        }
      });
    case 'analyst_assigned_date':
      return myArr.map((item) => {
        if (row?.[`${item[0]}_assigned_date`] && item[1] === 'assigned') {
          return row[`${item[0]}_assigned_date`];
        }
      });
    case 'analyst_target_date':
      return myArr.map((item) => {
        if (row?.[`${item[0]}_target_date`] && item[1] === 'target') {
          return row[`${item[0]}_target_date`];
        }
      });
    default:
      break;
  }
};
const { SEARCH_TYPES } = constants;
const DOWNLOADS_TABLE_HEADERS = {
  ROOT_LEVEL: [
    {
      label: 'Search Type',
      dataExtractor: (row) => {
        return SEARCH_TYPES[row?.search_type].label;
      },
    },
    {
      label: 'Assigned Date',
      dataExtractor: (row) => {
        return moment(row?.timestamp).format('YYYY-MM-DD');
      },
    },
    {
      label: 'Target Date',
      dataExtractor: (row) => {
        return row?.target_date;
      },
    },
    {
      label: 'Name',
      dataExtractor: (row) => {
        return row?.name?.charAt(0).toUpperCase() + row?.name?.slice(1);
      },
    },
    {
      label: 'Aliases',
      dataExtractor: (row) => {
        return row?.aliases !== '' ? row?.aliases : 'N/A';
      },
    },
    {
      label: 'Alert List Type',
      dataExtractor: (row) => {
        return row?.alert_list_type !== '' ? row?.alert_list_type : '';
      },
    },
    {
      label: 'Country',

      dataExtractor: (row) => {
        return row?.country !== '' ? row?.country : 'N/A';
      },
    },
    {
      label: 'Address',
      dataExtractor: (row) => {
        return row?.address !== '' ? row?.address : 'N/A';
      },
    },
    {
      label: 'Zipcode',
      dataExtractor: (row) => {
        return row?.zipcode === '' || row?.zipcode === null
          ? 'N/A'
          : row?.zipcode;
      },
    },
    {
      label: 'DOB',
      dataExtractor: (row) => {
        return row?.date_of_birth === '' || row?.date_of_birth === null
          ? 'N/A'
          : moment(row?.date_of_birth).format('YYYY-MM-DD');
      },
    },
    {
      label: 'Risk Type',
      dataExtractor: (row) => {
        const riskType = row?.risk_rating_result
          ? row?.risk_rating_result?.risk_type
          : '';
        const formattedRiskType =
          riskType !== ''
            ? riskType.charAt(0).toUpperCase() + riskType.slice(1)
            : 'N/A';

        return formattedRiskType;
      },
    },
    {
      label: 'Search ID',
      dataExtractor: (row) => {
        return row?.search_id;
      },
    },
    {
      label: 'Customer ID',
      dataExtractor: (row) => {
        return row?.review_id;
      },
    },
  ],
  INDIVIDUAL_ADDITIONAL_INFO: [
    {
      label: 'Date of Birth',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Nationality',
      dataExtractor: (row) => {
        return row?.nationality;
      },
    },
    {
      label: 'Gender',
      dataExtractor: (row) => {
        return row?.gender;
      },
    },
    {
      label: 'Passport Number',
      dataExtractor: (row) => {
        return row?.passport_number;
      },
    },
    {
      label: 'National ID',
      dataExtractor: (row) => {
        return row?.national_id;
      },
    },
    {
      label: 'PAN',
      dataExtractor: (row) => {
        return row?.pan;
      },
    },
  ],
  ENTITY_ADDITIONAL_INFO: [
    {
      label: 'Date Of Establishment',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'ISIN',
      dataExtractor: (row) => {
        return row?.isin;
      },
    },
    {
      label: 'CIN',
      dataExtractor: (row) => {
        return row?.cin;
      },
    },
    {
      label: 'Headquarter',
      dataExtractor: (row) => {
        return row?.headquarter;
      },
    },
  ],
  AIRCRAFT_ADDITIONAL_INFO: [
    {
      label: 'Operator',
      dataExtractor: (row) => {
        return row?.operator;
      },
    },
    {
      label: 'Manufacture Serial Number',
      dataExtractor: (row) => {
        return row?.manufacture_serial_number;
      },
    },
    {
      label: 'Construction Number',
      dataExtractor: (row) => {
        return row?.construction_number;
      },
    },
    {
      label: 'Program',
      dataExtractor: (row) => {
        return row?.program;
      },
    },
    {
      label: 'Aircraft Model',
      dataExtractor: (row) => {
        return row?.aircraft_model;
      },
    },
  ],
  VESSEL_ADDITIONAL_INFO: [
    {
      label: 'Registered Owner',
      dataExtractor: (row) => {
        return row?.registered_owner;
      },
    },
    {
      label: 'Parent Company',
      dataExtractor: (row) => {
        return row?.parent_company;
      },
    },
    {
      label: 'Vessel Type',
      dataExtractor: (row) => {
        return row?.vessel_type;
      },
    },
    {
      label: 'IMO',
      dataExtractor: (row) => {
        return row?.imo;
      },
    },
    {
      label: 'MMSI No.',
      dataExtractor: (row) => {
        return row?.mmsi;
      },
    },
    {
      label: 'Flag State',
      dataExtractor: (row) => {
        return row?.flag_state;
      },
    },
  ],
  SL: [
    {
      label: 'Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Alias Name',
      dataExtractor: (row) => {
        return row?.alias_name?.length ? row?.alias_name?.toString() : '';
      },
    },
    {
      label: 'Source Name',
      dataExtractor: (row) => {
        return row?.source_name;
      },
    },
    {
      label: 'Source Description',
      dataExtractor: (row) => {
        return row?.source_description;
      },
    },
    {
      label: 'Source Link',
      dataExtractor: (row) => {
        return row?.source_link;
      },
    },
    {
      label: 'Date Of Birth',
      dataExtractor: (row) => {
        return row?.data_of_birth;
      },
    },
    {
      label: 'Sanctioned Country',
      dataExtractor: (row) => {
        return row?.sanctionedCountry?.length
          ? row?.sanctionedCountry?.toString()
          : '';
      },
    },
    {
      label: 'IDs Matched',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString();
      },
    },
    {
      label: 'Name Match Score',
      dataExtractor: (row) => {
        return row?.name_match_score;
      },
    },
    {
      label: 'DOB Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.dob_match_score;
      },
    },
    {
      label: 'Address Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.address_match_score;
      },
    },
    {
      label: 'Overall Score',
      dataExtractor: (row) => {
        return row?.sams_score?.overall_score;
      },
    },
  ],
  SANCTIONS_COUNTRIES: [
    {
      label: 'Sanctions Type :',
      dataExtractor: () => {
        return 'sanctioned Country';
      },
    },
    {
      label: 'List Name :',
      dataExtractor: (row) => {
        return row?.listName;
      },
    },
    {
      label: 'Country :',
      dataExtractor: (row) => {
        return row?.country;
      },
    },
    {
      label: 'Source URL1 :',
      dataExtractor: (row) => {
        return row?.sourceURL1;
      },
    },
    {
      label: 'Source URL2 :',
      dataExtractor: (row) => {
        return row?.sourceURL2;
      },
    },
    {
      label: 'Description :',
      dataExtractor: (row) => {
        return row?.description;
      },
    },
    {
      label: 'Banned Subjects :',
      dataExtractor: (row) => {
        return row?.bannedSubjects;
      },
    },
    {
      label: 'Exemptions/Allowed :',
      dataExtractor: (row) => {
        return row?.Exemptions;
      },
    },
  ],
  AMS: [
    {
      label: 'URL',
      dataExtractor: (row) => {
        return row?.url;
      },
    },
    {
      label: 'Name_Match Score',
      dataExtractor: (row) => {
        return row?.name_matching_score;
      },
    },
    {
      label: 'Sentiment Score',
      dataExtractor: (row) => {
        return row?.sentiment_score?.score;
      },
    },
    {
      label: 'Source_Credibility Score',
      dataExtractor: (row) => {
        return row?.source_credibility_score;
      },
    },
    {
      label: 'Overall Score',
      dataExtractor: (row) => {
        return row?.overall_score;
      },
    },
  ],
  PEP: [
    {
      label: 'Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    // {
    //   label: 'Designation',
    //   dataExtractor: (row) => {
    //     return row?.designation;
    //   },
    // },
    {
      label: 'Organisation',
      dataExtractor: (row) => {
        return row?.organisation;
      },
    },
    {
      label: 'Overall Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.overall_score;
      },
    },
    {
      label: 'Name Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.name_match_score;
      },
    },
    {
      label: 'DOB Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.dob_match_score;
      },
    },
    {
      label: 'Address Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.address_match_score;
      },
    },
  ],
  ENTITY_PEP: [
    {
      label: 'Organisation Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Entity Status',
      dataExtractor: (row) => {
        return row?.entity_status;
      },
    },
    {
      label: 'PEP Type',
      dataExtractor: (row) => {
        return row?.pep_type;
      },
    },
    {
      label: 'Overall Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.overall_score;
      },
    },
    {
      label: 'Name Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.name_match_score;
      },
    },
    {
      label: 'DOB Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.dob_match_score;
      },
    },
    {
      label: 'Address Match Score',
      dataExtractor: (row) => {
        return row?.sams_score?.address_match_score;
      },
    },
  ],
  CORPORATE_DETAILS: [
    {
      label: 'Company Name',
      dataExtractor: (row) => {
        return row?.COMPANY_NAME;
      },
    },
    {
      label: 'CIN',
      dataExtractor: (row) => {
        return row?.CIN;
      },
    },
    {
      label: 'ROC Code',
      dataExtractor: (row) => {
        return row?.ROC;
      },
    },
    {
      label: 'Company Category',
      dataExtractor: (row) => {
        return row?.CATEGORY;
      },
    },
    {
      label: 'Company Subcategory',
      dataExtractor: (row) => {
        return row?.SUBCATEGORY;
      },
    },
    {
      label: 'Class of Company',
      dataExtractor: (row) => {
        return row?.CLASS;
      },
    },
    {
      label: 'Authorised Capital(Rs)',
      dataExtractor: (row) => {
        return row?.AUTHORIZED_CAPITAL;
      },
    },
    {
      label: 'Paid up Capital(Rs)',
      dataExtractor: (row) => {
        return row?.PAIDUP_CAPITAL;
      },
    },
    {
      label: 'Number of members',
      dataExtractor: (row) => {
        return row?.NUMBER_OF_MEMBERS?.toString();
      },
    },
    {
      label: 'Email ID',
      dataExtractor: (row) => {
        return row?.EMAIL;
      },
    },
    {
      label: 'Listing',
      dataExtractor: (row) => {
        return row?.LISTING_STATUS;
      },
    },
    {
      label: 'Active Compliance',
      dataExtractor: (row) => {
        return row?.ACTIVE_COMPLIANCE;
      },
    },
    {
      label: 'Suspended at Stock Exchange',
      dataExtractor: (row) => {
        return row?.SUSPENDED_AT_STOCK;
      },
    },
    {
      label: 'Date of Last AGM',
      dataExtractor: (row) => {
        return row?.LAST_AGM;
      },
    },
    {
      label: 'Date of Balance Sheet',
      dataExtractor: (row) => {
        return row?.LAST_BALANCESHEET;
      },
    },
    {
      label: 'Date of Incorporation',
      dataExtractor: (row) => {
        return row?.DATE_OF_REGISTRATION;
      },
    },
    {
      label: 'Address other then R/O',
      dataExtractor: (row) => {
        return row?.ADDRESS_OTHER_THAN_RO;
      },
    },
  ],
  CHARGES: [
    {
      label: 'SRN',
      dataExtractor: (row) => {
        return row?.SRN;
      },
    },
    {
      label: 'Charges ID',
      dataExtractor: (row) => {
        return row?.CHARGE_ID;
      },
    },
    {
      label: 'Charges Holder Name',
      dataExtractor: (row) => {
        return row?.CHARGE_HOLDER;
      },
    },
    {
      label: 'Date of Creation',
      dataExtractor: (row) => {
        return row?.DATE_CREATE;
      },
    },
    {
      label: 'Date of Modification',
      dataExtractor: (row) => {
        return row?.DATE_MODIFIED;
      },
    },
    {
      label: 'Date of Satisfaction',
      dataExtractor: (row) => {
        return row?.DATE_SATISFIED;
      },
    },
    {
      label: 'Amount',
      dataExtractor: (row) => {
        return row?.AMOUNT;
      },
    },
    {
      label: 'Address',
      dataExtractor: (row) => {
        return row?.ADDRESS;
      },
    },
  ],
  SIGNATORIES: [
    {
      label: 'DIN',
      dataExtractor: (row) => {
        return row?.DIN;
      },
    },
    {
      label: 'Full Name',
      dataExtractor: (row) => {
        return row?.NAME;
      },
    },
    {
      label: 'Designation',
      dataExtractor: (row) => {
        return row?.DESIGNATION;
      },
    },
    {
      label: 'Start Date',
      dataExtractor: (row) => {
        return row?.DATE_JOIN;
      },
    },
    {
      label: 'End Date',
      dataExtractor: (row) => {
        return row?.DATE_RESIGN;
      },
    },
  ],
  LEGAL: [
    {
      label: 'Headline',
      dataExtractor: (row) => {
        return row?.headline;
      },
    },
    {
      label: 'Title',
      dataExtractor: (row) => {
        return row?.title;
      },
    },
    {
      label: 'URL',
      dataExtractor: (row) => {
        return row?.url;
      },
    },

    {
      label: 'Document Source',
      dataExtractor: (row) => {
        return row?.docsource;
      },
    },
    {
      label: 'Publish Date',
      dataExtractor: (row) => {
        return row?.publishdate;
      },
    },
  ],
};

const HISTORY_SANCTIONS_TABLE_DATA = {
  INDIVIDUAL: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Full Name :',
      dataExtractor: (row) => {
        return row?.name ?? 'N/A';
      },
    },
    {
      label: 'Alias :',
      dataExtractor: (row) => {
        return row?.alias_name &&
          Array.isArray(row?.alias_name) &&
          row?.alias_name?.length > 0
          ? row?.alias_name?.join(', ')
          : row?.alias_name?.length
          ? row?.alias_name?.toString()
          : 'N/A';
      },
    },
    {
      label: 'Address : ',
      dataExtractor: (row) => {
        if (Array.isArray(row?.matched_address)) {
          return row?.matched_address;
        }
        if (typeof row?.matched_address === 'string') {
          return row?.matched_address?.split('$**$');
        }
        return row?.matched_address ?? 'N/A';
      },
    },
    {
      label: 'Gender :',
      dataExtractor: (row) => {
        return row?.gender?.length ? row?.gender : 'N/A';
      },
    },
    {
      label: 'Sanc. Countries :',
      title: 'Sanctioned Countries',
      dataExtractor: (row) => {
        return row?.sanctionedCountry?.length
          ? row?.sanctionedCountry?.toString()
          : 'N/A';
      },
    },
    {
      label: 'Passport No. : ',
      dataExtractor: (row) => {
        return row?.documents?.passport?.length
          ? row?.documents?.passport
          : 'N/A';
      },
    },
    {
      label: 'TIN/PAN :',
      dataExtractor: (row) => {
        return row?.documents?.pan !== '' && row?.documents?.pan !== null
          ? row?.documents?.pan
          : row?.documents?.tin;
      },
    },
    {
      label: 'DOB :',
      title: 'Date of Birth',
      dataExtractor: (row) => {
        return row?.date_of_birth?.length ? row?.date_of_birth : 'N/A';
      },
    },
    {
      label: 'Ass. Countries :',
      title: 'Associated Countries',
      dataExtractor: (row) => {
        return row?.associated_country?.length
          ? row?.associated_country?.toString()
          : 'N/A';
      },
    },

    {
      label: 'National ID :',
      dataExtractor: (row) => {
        return row?.documents?.national_id?.length
          ? row?.documents?.national_id
          : 'N/A';
      },
    },
    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString() === '0:0'
          ? 'N/A'
          : row?.sams_score?.matched_id?.toString();
      },
    },
    {
      label: 'Nationality :',
      dataExtractor: (row) => {
        return row?.nationality?.length ? row?.nationality?.toString() : 'N/A';
      },
    },
    {
      label: 'Citizenship :',
      dataExtractor: (row) => {
        return row?.citizenship?.length ? row?.citizenship?.toString() : 'N/A';
      },
    },
  ],
  ENTITY: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Org. Name :',
      title: 'Organisation Name',
      dataExtractor: (row) => {
        return row?.name ?? 'N/A';
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.alias_name &&
          Array.isArray(row?.alias_name) &&
          row?.alias_name?.length > 0
          ? row?.alias_name.join(', ')
          : row?.alias_name?.length
          ? row?.alias_name.toString()
          : 'N/A';
      },
    },
    {
      label: 'Address : ',
      dataExtractor: (row) => {
        if (Array.isArray(row?.matched_address)) {
          return row?.matched_address;
        }
        if (typeof row?.matched_address === 'string') {
          return row?.matched_address?.split('$**$');
        }
        return row?.matched_address ?? 'N/A';
      },
    },

    {
      label: 'Org ID :',
      title: 'Organisation ID',
      dataExtractor: (row) => {
        return row?.documents?.cin?.length ? row?.documents?.cin : 'N/A';
      },
    },
    {
      label: 'Sanc. Countries :',
      title: 'Sanctioned Countries',
      dataExtractor: (row) => {
        return row?.sanctionedCountry?.toString() ?? 'N/A';
      },
    },

    {
      label: 'TIN/PAN : ',
      title: 'Tax Id Number/Permanent Account Number',
      dataExtractor: (row) => {
        return row?.documents?.pan !== '' && row?.documents?.pan !== null
          ? row?.documents?.pan
          : row?.documents?.tin;
      },
    },
    {
      label: 'DOE :',
      title: 'Date of Establishment',
      dataExtractor: (row) => {
        return row?.date_of_birth?.length ? row?.date_of_birth : 'N/A';
      },
    },
    {
      label: 'Ass. Countries :',
      title: 'Associated Countries',
      dataExtractor: (row) => {
        return row?.associated_country?.length
          ? row?.associated_country?.toString()
          : 'N/A';
      },
    },
    {
      label: 'ISIN No. : ',
      title: 'International Securities Identification Number',
      dataExtractor: (row) => {
        return row?.documents?.isin?.length ? row?.documents?.isin : 'N/A';
      },
    },
    {
      label: 'GST No. : ',
      title: 'Goods and Services Tax No.',
      dataExtractor: (row) => {
        return row?.documents?.gst?.length ? row?.documents?.gst : 'N/A';
      },
    },
    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString() === '0:0'
          ? 'N/A'
          : row?.sams_score?.matched_id?.toString();
      },
    },
  ],
  VESSEL: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Vessel Name :',
      dataExtractor: (row) => {
        return row?.name ?? 'N/A';
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.alias_name &&
          Array.isArray(row?.alias_name) &&
          row?.alias_name?.length > 0
          ? row?.alias_name.join(', ')
          : row?.alias_name?.length
          ? row?.alias_name.toString()
          : 'N/A';
      },
    },
    {
      label: 'DOM :',
      title: 'Date of Manufacture',
      dataExtractor: (row) => {
        return row?.date_of_birth?.length ? row?.date_of_birth : 'N/A';
      },
    },
    {
      label: 'Tonnage :',
      dataExtractor: (row) => {
        return row?.tonnage?.length ? row?.tonnage : 'N/A';
      },
    },
    {
      label: 'Parent Company :',

      dataExtractor: (row) => {
        return row?.parent_company?.length ? row?.parent_company : 'N/A';
      },
    },
    {
      label: 'Sanc. Countries :',
      title: 'Sanctioned Countries',
      dataExtractor: (row) => {
        return row?.sanctionedCountry?.toString();
      },
    },

    {
      label: 'IMO No. : ',

      dataExtractor: (row) => {
        return row?.documents?.imo?.length ? row?.documents?.imo : 'N/A';
      },
    },

    {
      label: 'Call Sign : ',
      dataExtractor: (row) => {
        return row?.call_sign?.length ? row?.call_sign : 'N/A';
      },
    },
    {
      label: 'Flag State: ',
      dataExtractor: (row) => {
        return row?.flag_state?.length ? row?.flag_state : 'N/A';
      },
    },
    {
      label: 'Reg owner :',
      title: 'Registered Owner',
      dataExtractor: (row) => {
        return row?.registered_owner?.length ? row?.registered_owner : 'N/A';
      },
    },
    {
      label: 'Ass. Countries :',
      title: 'Associated Countries',
      dataExtractor: (row) => {
        return row?.associated_country?.length
          ? row?.associated_country?.toString()
          : 'N/A';
      },
    },
    {
      label: 'MMSI No. :',
      title: 'Maritime Mobile Service Identity',
      dataExtractor: (row) => {
        return row?.documents?.mmsi?.length ? row?.documents?.mmsi : 'N/A';
      },
    },

    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString() === '0:0'
          ? 'N/A'
          : row?.sams_score?.matched_id?.toString();
      },
    },
  ],
  AIRCRAFT: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Aircraft Name :',
      dataExtractor: (row) => {
        return row?.name ?? 'N/A';
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.alias_name &&
          Array.isArray(row?.alias_name) &&
          row?.alias_name?.length > 0
          ? row?.alias_name.join(', ')
          : row?.alias_name?.length
          ? row?.alias_name.toString()
          : 'N/A';
      },
    },
    {
      label: 'DOM :',
      title: 'Date of Manufacture',
      dataExtractor: (row) => {
        return row?.date_of_birth?.length ? row?.date_of_birth : 'N/A';
      },
    },
    {
      label: 'Operator :',
      dataExtractor: (row) => {
        return row?.documents?.Operator?.length
          ? row?.documents?.Operator
          : 'N/A';
      },
    },
    {
      label: 'Sanc. Countries :',
      title: 'Sanctioned Countries',
      dataExtractor: (row) => {
        return row?.sanctionedCountry?.toString() ?? 'N/A';
      },
    },
    {
      label: 'MSN No. :',
      title: "Aircraft Manufacturer's Serial Number",
      dataExtractor: (row) => {
        return row?.documents?.manufacture_serial_number?.length
          ? row?.documents?.manufacture_serial_number
          : 'N/A';
      },
    },
    {
      label: 'Aircraft Model :',
      dataExtractor: (row) => {
        return row?.model;
      },
    },
    {
      label: 'Program :',
      dataExtractor: (row) => {
        return row?.program?.length ? row?.program : 'N/A';
      },
    },
    {
      label: 'Ass. Countries :',
      title: 'Associated Countries',
      dataExtractor: (row) => {
        return row?.associated_country?.length
          ? row?.associated_country?.toString()
          : 'N/A';
      },
    },
    {
      label: 'Cons No. :',
      title: 'Construction Number',
      dataExtractor: (row) => {
        return row?.documents?.construction_number?.length
          ? row?.documents?.construction_number
          : 'N/A';
      },
    },
    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString() === '0:0'
          ? 'N/A'
          : row?.sams_score?.matched_id?.toString();
      },
    },
  ],

  SCORES: [
    {
      label: 'Name :',
      dataExtractor: (row) => {
        return row?.sams_score?.name_match_score;
      },
      color: 'warning.main',
    },
    {
      label: 'date',
      dataExtractor: (row) => {
        return row?.sams_score?.dob_match_score;
      },
      color: 'error.main',
    },
    {
      label: 'Address :',
      dataExtractor: (row) => {
        return row?.sams_score?.address_match_score;
      },

      color: 'success.light',
    },
    {
      label: 'IDs :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id[0] === 'N/A'
          ? 'N/A'
          : row?.sams_score?.matched_id === '0:0'
          ? 0
          : 100;
      },
      color: 'text.disabled',
    },
    {
      label: 'SAMS Score :',
      dataExtractor: (row) => {
        return row?.sams_score?.overall_score;
      },
      color: 'text.disabled',
    },
  ],
  PROPERTIES: [
    {
      label: 'Sanction List :',
      dataExtractor: (row) => {
        return row?.source_name;
      },
    },
    {
      label: 'Link :',
      dataExtractor: (row) => {
        return row?.source_link;
      },
    },
    {
      label: 'Description :',
      dataExtractor: (row) => {
        return row?.source_description;
      },
    },
  ],
  SANCTIONS_COUNTRIES: [
    {
      label: 'Sanctions Type :',
      dataExtractor: (row) => {
        return row?.sanctions_type ?? 'Sanctioned Country';
      },
    },
    {
      label: 'List Name :',
      dataExtractor: (row) => {
        return row?.listName ?? 'N/A';
      },
    },
    {
      label: 'Country :',
      dataExtractor: (row) => {
        return row?.country ?? 'N/A';
      },
    },
    {
      label: 'Country Alias :',
      dataExtractor: (row) => {
        return row?.country_alias?.join(', ') ?? 'N/A';
      },
    },
    {
      label: 'State :',
      dataExtractor: (row) => {
        return row?.state ?? 'N/A';
      },
    },
    {
      label: 'State Alias :',
      dataExtractor: (row) => {
        return row?.state_alias?.join(', ') ?? 'N/A';
      },
    },
    {
      label: 'City :',
      dataExtractor: (row) => {
        return row?.city ?? 'N/A';
      },
    },
    {
      label: 'City Alias :',
      dataExtractor: (row) => {
        return row?.city_alias?.join(', ') ?? 'N/A';
      },
    },
    {
      label: 'Source URL1 :',
      dataExtractor: (row) => {
        return row?.sourceURL1?.[0] !== null ? row?.sourceURL1 : 'N/A';
      },
    },
    {
      label: 'Source URL2 :',
      dataExtractor: (row) => {
        return row?.sourceURL2?.[0] !== null ? row?.sourceURL2 : 'N/A';
      },
    },
    {
      label: 'Description :',
      dataExtractor: (row) => {
        return row?.description ?? 'N/A';
      },
    },
    {
      label: 'Banned Subjects :',
      dataExtractor: (row) => {
        return row?.bannedSubjects ?? 'N/A';
      },
    },
    {
      label: 'Exemptions/Allowed :',
      dataExtractor: (row) => {
        return row?.Exemptions ?? 'N/A';
      },
    },
  ],
};

const HISTORY_PEP_TABLE_DATA = {
  INDIVIDUAL: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Full Name :',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Alias :',
      dataExtractor: (row) => {
        return row?.alias_names?.join(', ');
      },
    },
    {
      label: 'Address : ',
      dataExtractor: (row) => {
        if (Array.isArray(row?.address)) {
          return row?.address?.length ? row?.address : 'N/A';
        }
        if (typeof row?.address === 'string') {
          return row?.address?.split('$**$') ?? 'N/A';
        }
        return row?.address ?? 'N/A';
      },
    },
    {
      label: 'PEP Type :',
      dataExtractor: (row) => {
        return row?.pep_type?.length ? row?.pep_type?.join(', ') : 'N/A';
      },
    },
    {
      label: 'Associate :',
      dataExtractor: (row) => {
        return row?.associates?.associate?.join(', ') ?? 'N/A';
      },
    },
    {
      label: 'Gender :',
      dataExtractor: (row) => {
        return row?.gender;
      },
    },
    {
      label: 'Place of birth :',
      title: 'Place of birth',
      dataExtractor: (row) => {
        return row?.place_of_birth?.toString();
      },
    },
    {
      label: 'Nationality :',
      dataExtractor: (row) => {
        return row?.nationality?.length ? row?.nationality : 'N/A';
      },
    },
    {
      label: 'Passport No. :',
      dataExtractor: (row) => {
        return row?.passport_number;
      },
    },
    {
      label: 'DOB :',
      title: 'Date of Birth',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Organisation :',
      dataExtractor: (row) => {
        return row?.organisation;
      },
    },
    {
      label: 'SSN No. :',
      dataExtractor: (row) => {
        return row?.ssn;
      },
    },
    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        const matchedId = Array.isArray(row?.sams_score?.matched_id)
          ? row?.sams_score?.matched_id
          : [row?.sams_score?.matched_id];

        return matchedId[0] === '0' || matchedId[1] === '0'
          ? ['N/A']
          : matchedId;
      },
    },

    {
      label: 'Country : ',
      dataExtractor: (row) => {
        if (Array.isArray(row?.country)) {
          return row?.country;
        }
        if (typeof row?.country === 'string') {
          return row?.country?.split('$**$');
        }
        return row?.country;
      },
    },
    {
      label: 'Citizenship :',
      dataExtractor: (row) => {
        return row?.citizenship?.length ? row?.citizenship : 'N/A';
      },
    },
  ],
  ENTITY: [
    {
      label: 'Search Type :',
      dataExtractor: (row) => {
        return row?.article_search_type ?? 'N/A';
      },
    },
    {
      label: 'Org. Name :',
      title: 'Organisation Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Alias :',
      title: 'Alias',
      dataExtractor: (row) => {
        return row?.alias_names?.join(', ');
      },
    },
    {
      label: 'Address : ',
      title: 'Address',
      dataExtractor: (row) => {
        if (Array.isArray(row?.address)) {
          return row?.address;
        }
        if (typeof row?.address === 'string') {
          return row?.address?.split('$**$');
        }
        return row?.address;
      },
    },
    {
      label: 'PEP Type :',
      title: 'PEP Type',
      dataExtractor: (row) => {
        return row?.pep_type?.join(', ');
      },
    },
    {
      label: 'DOE :',
      title: 'Date of Establishment',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Country : ',
      title: 'Country',
      dataExtractor: (row) => {
        if (Array.isArray(row?.country)) {
          return row?.country;
        }
        if (typeof row?.country === 'string') {
          return row?.country?.split('$**$');
        }
        return row?.country;
      },
    },
    {
      label: 'Entity Status :',
      title: 'Entity Status',
      dataExtractor: (row) => {
        return row?.entity_status;
      },
    },
    {
      label: 'Reg. No. :',
      title: 'Registration No.',
      dataExtractor: (row) => {
        return row?.registration_no;
      },
    },
    {
      label: 'Entity Type :',
      title: 'Entity Type',
      dataExtractor: (row) => {
        return row?.entity_type;
      },
    },
  ],
  FAMILY_TREE: [
    {
      label: 'Father :',
      dataExtractor: (row) => {
        return row['family-tree']?.Father;
      },
    },
    {
      label: 'Mother :',
      dataExtractor: (row) => {
        return row['family-tree']?.Mother;
      },
    },
    {
      label: 'Son :',
      dataExtractor: (row) => {
        return row['family-tree']?.Son;
      },
    },
    {
      label: 'Daughter :',
      dataExtractor: (row) => {
        return row['family-tree']?.Daughter;
      },
    },
  ],
  OTHER: [
    {
      label: 'Org. Name :',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Nationality',
      dataExtractor: (row) => {
        return row?.nationality;
      },
    },
    {
      label: 'DOE :',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Organisation :',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'IDs Matched :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id?.toString();
      },
    },
  ],

  PROPERTIES: [
    {
      label: 'PEP List :',
      dataExtractor: (row) => {
        return row?.source_name;
      },
    },
    {
      label: 'Link :',
      dataExtractor: (row) => {
        return row?.source_link;
      },
    },
    {
      label: 'Other Link :',
      dataExtractor: (row) => {
        return row?.other_urls;
      },
    },
    {
      label: 'Description :',
      dataExtractor: (row) => {
        return row?.source_description;
      },
    },
  ],

  SCORES: [
    {
      label: 'Name :',
      dataExtractor: (row) => {
        return row?.sams_score?.name_match_score;
      },
    },
    {
      label: 'date',
      dataExtractor: (row) => {
        return row?.sams_score?.dob_match_score;
      },
    },
    {
      label: 'Address : ',
      dataExtractor: (row) => {
        return row?.sams_score?.address_match_score;
      },
    },
    {
      label: 'IDs :',
      dataExtractor: (row) => {
        return row?.sams_score?.matched_id[0] === 'N/A'
          ? 'N/A'
          : row?.sams_score?.matched_id[0] === '0' ||
            row?.sams_score?.matched_id[1] === '0'
          ? 0
          : 100;
      },
    },
    {
      label: 'SAMS Score :',
      dataExtractor: (row) => {
        return row?.sams_score?.overall_score;
      },
    },
  ],
};

const HISTORY_AMS_TABLE_DATA = {
  SCORES: [
    {
      label: 'Name Matching :',
      dataExtractor: (row) => {
        return row?.name_matching_score;
      },
      color: 'warning.main',
    },
    {
      label: 'Sentiment :',
      dataExtractor: (row) => {
        return row?.sentiment_score?.score;
      },
      color: 'error.main',
    },
    {
      label: 'Source Credibility :',
      dataExtractor: (row) => {
        return row?.source_credibility_score;
      },
      color: 'success.light',
    },
    {
      label: 'SAMS Score :',
      dataExtractor: (row) => {
        return row?.overall_score;
      },
      color: 'text.disabled',
    },
  ],
};
const HISTORY_CORPORATE_TABLE_DATA = {
  COMPANY: [
    {
      label: 'CIN :',
      dataExtractor: (row) => {
        return row?.CIN;
      },
    },
    {
      label: 'Company Name :',
      dataExtractor: (row) => {
        return row?.masterData?.COMPANY_NAME;
      },
    },
    {
      label: 'ROC Code :',
      dataExtractor: (row) => {
        return row?.masterData?.ROC;
      },
    },
    {
      label: 'Company Category',
      dataExtractor: (row) => {
        return row?.masterData?.CATEGORY;
      },
    },
    {
      label: 'Company Subcategory : ',
      dataExtractor: (row) => {
        return row?.masterData?.SUBCATEGORY;
      },
    },
    {
      label: 'Class of Company : ',
      dataExtractor: (row) => {
        return row?.masterData?.CLASS;
      },
    },
    {
      label: 'Authorised Capital(Rs) : ',
      title: 'Authorised Capital(Rs)',
      dataExtractor: (row) => {
        return row?.masterData?.AUTHORIZED_CAPITAL;
      },
    },
    {
      label: 'Paid up Capital(Rs) : ',
      dataExtractor: (row) => {
        return row?.masterData?.PAIDUP_CAPITAL;
      },
    },
    {
      label: 'Number of members : ',
      dataExtractor: (row) => {
        return row?.masterData?.NUMBER_OF_MEMBERS?.toString();
      },
    },
    {
      label: 'Email ID : ',
      dataExtractor: (row) => {
        return row?.masterData?.EMAIL;
      },
    },
    {
      label: 'Listing : ',
      dataExtractor: (row) => {
        return row?.masterData?.LISTING_STATUS;
      },
    },
    {
      label: 'Active Compliance : ',
      dataExtractor: (row) => {
        return row?.masterData?.ACTIVE_COMPLIANCE;
      },
    },
    {
      label: 'Suspended at Stock Exchange : ',
      title: 'Suspended at Stock Exchange',
      dataExtractor: (row) => {
        return row?.masterData?.SUSPENDED_AT_STOCK;
      },
    },
    {
      label: 'Date of Last AGM : ',
      dataExtractor: (row) => {
        return row?.masterData?.LAST_AGM;
      },
    },
    {
      label: 'Date of Balance Sheet : ',
      dataExtractor: (row) => {
        return row?.masterData?.LAST_BALANCESHEET;
      },
    },
    {
      label: 'Date of Incorporation : ',
      dataExtractor: (row) => {
        return row?.masterData?.sams_score;
      },
    },
    {
      label: 'Address other then R/O : ',
      dataExtractor: (row) => {
        return row?.masterData?.ADDRESS_OTHER_THAN_RO;
      },
    },
  ],
};
const BULK_TABLE_HEADERS = {
  INDIVIDUAL_INFO: [
    {
      label: 'Full Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Gender',
      dataExtractor: (row) => {
        return row?.gender;
      },
    },
    {
      label: 'DOB',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Nationality',
      dataExtractor: (row) => {
        return row?.nationality;
      },
    },
    {
      label: 'Country',
      dataExtractor: (row) => {
        return row?.country?.toString();
      },
    },
    {
      label: 'Country Of Jurisdiction',

      dataExtractor: (row) => {
        return row?.country_of_jurisdiction;
      },
    },
    {
      label: 'Address',
      dataExtractor: (row) => {
        return row?.address;
      },
    },
    {
      label: 'Passport Number',
      dataExtractor: (row) => {
        return row?.passport_number;
      },
    },
    {
      label: 'Phone Number',
      dataExtractor: (row) => {
        return row?.phone_number;
      },
    },
    {
      label: 'Email',
      dataExtractor: (row) => {
        return row?.email;
      },
    },
    {
      label: 'TIN/PAN',
      dataExtractor: (row) => {
        return row?.pan;
      },
    },
    {
      label: 'National ID',
      dataExtractor: (row) => {
        return row?.national_id;
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.aliases;
      },
    },
  ],

  ENTITY_INFO: [
    {
      label: 'Full Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Date Of Establishment',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'ISIN',
      dataExtractor: (row) => {
        return row?.isin;
      },
    },
    {
      label: 'CIN',
      dataExtractor: (row) => {
        return row?.cin;
      },
    },
    {
      label: 'Headquarter',
      dataExtractor: (row) => {
        return row?.headquarters;
      },
    },
  ],
  AIRCRAFT_INFO: [
    {
      label: 'Aircraft Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Aircraft Manufacture Date',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Program',
      dataExtractor: (row) => {
        return row?.program;
      },
    },
    {
      label: 'Country',
      dataExtractor: (row) => {
        return row?.country?.toString();
      },
    },
    {
      label: 'Aircraft Model',
      dataExtractor: (row) => {
        return row?.aircraft_model;
      },
    },
    {
      label: 'Country Of Jurisdiction',
      dataExtractor: (row) => {
        return row?.country_of_jurisdiction;
      },
    },
    {
      label: 'Operator',
      dataExtractor: (row) => {
        return row?.operator;
      },
    },
    {
      label: 'Address',
      dataExtractor: (row) => {
        return row?.address;
      },
    },
    {
      label: 'Manufacture Serial Number(MSN)',
      dataExtractor: (row) => {
        return row?.manufacture_serial_number;
      },
    },
    {
      label: 'Construction Number',
      dataExtractor: (row) => {
        return row?.construction_number;
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.aliases?.toString();
      },
    },
  ],
  VESSEL_INFO: [
    {
      label: 'Vessel Name',
      dataExtractor: (row) => {
        return row?.name;
      },
    },
    {
      label: 'Vessel Manufacture date',
      dataExtractor: (row) => {
        return row?.date_of_birth;
      },
    },
    {
      label: 'Vessel Type',
      dataExtractor: (row) => {
        return row?.vessel_type;
      },
    },
    {
      label: 'Country',
      dataExtractor: (row) => {
        return row?.country?.toString();
      },
    },
    {
      label: 'Flag State',
      dataExtractor: (row) => {
        return row?.flag_state;
      },
    },
    {
      label: 'Country Of Jurisdiction',
      dataExtractor: (row) => {
        return row?.country_of_jurisdiction;
      },
    },
    {
      label: 'Registered Owner',
      dataExtractor: (row) => {
        return row?.registered_owner;
      },
    },
    {
      label: 'Parent Company',
      dataExtractor: (row) => {
        return row?.parent_company;
      },
    },
    {
      label: 'Address',
      dataExtractor: (row) => {
        return row?.address;
      },
    },
    {
      label: 'IMO',
      dataExtractor: (row) => {
        return row?.imo;
      },
    },
    {
      label: 'MMSI No.',
      dataExtractor: (row) => {
        return row?.mmsi;
      },
    },
    {
      label: 'Alias',
      dataExtractor: (row) => {
        return row?.aliases?.toString();
      },
    },
  ],
};

export {
  DOWNLOADS_TABLE_HEADERS,
  HISTORY_SANCTIONS_TABLE_DATA,
  HISTORY_PEP_TABLE_DATA,
  HISTORY_AMS_TABLE_DATA,
  HISTORY_CORPORATE_TABLE_DATA,
  BULK_TABLE_HEADERS,
};
