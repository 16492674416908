/* eslint-disable no-nested-ternary */
// @ts-nocheck
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useMultipartFileUploadToS3 } from 'solytics-frontend';
import moment from 'moment';
import { Utils } from '../utils';
import { API_ENDPOINTS } from '../const/ApiEndpoints';
import { selectAuth } from '../state/slices/appSlice';
import useRuleEngine from './useRuleEngine';

/**
 * @function useInitiate
 * @description a custom hook which handles APIs related to Initiating searches
 * @returns {object} object
 */
const useInitiate = () => {
  const { countryList, fetchCountryList } = useRuleEngine();
  const { user } = useSelector(selectAuth);
  const [bagofwords, setbagofwords] = useState([]);
  const [checkerLevel, setCheckerLevels] = useState('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [fileData, setfileData] = useState(undefined);
  const [csvData, setCsvData] = useState([]);
  const [uploadData, setUploadData] = useState({});
  const [isCsvLoading, updateCsvDisplayStatus] = useState(false);
  const [listType, setListType] = React.useState('b');
  const [count, setCount] = useState({
    alert: 0,
    non_alert: 0,
    progress: 0,
    status: 'p',
  });
  const { enqueueSnackbar } = useSnackbar();
  const [sanction, setSanction] = useState([]);
  const [sanctionList, setSanctionList] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [selectedList, setSelectedList] = useState([]);
  const [stepsWiseFormData, setStepWiseFormData] = React.useState({});
  const [bulkBagOfWords, setBulkBagOfWords] = useState([]);
  const [batchID, setBatchId] = useState('');
  const [teamList, setTeamList] = useState([]);
  const [industry, setIndustry] = useState([]);
  const [product, setProduct] = useState([]);
  const [occupation, setOccupation] = useState([]);
  const [riskParameters, setRiskParameters] = useState({});
  const [channel, setChannel] = useState({});
  const [transactionType, setTransactionType] = useState({});
  const [productType, setProductType] = useState({});
  const [allRules, setAllRules] = useState({});
  const [transactionResponse, setTransactionResponse] = useState([]);
  const [recipientTransactionResponse, setRecipientTransactionResponse] =
    useState([]);
  const [crrMandatoryParameterList, setCrrMandatoryParameterList] = useState(
    {}
  );
  const [googleAmlTaxonomy, setGoogleAmlTaxonomy] = useState([]);
  const [baiduAmlTaxonomy, setBaiduAmlTaxonomy] = useState([]);
  const [visibleScreens, setVisibleScreens] = React.useState({
    initiate_screenType: false,
    individualDetails: false,
    reqSearch: false,
    targ_Date: false,
    freq: false,
    alert: false,
    bulk: false,
    ongoingMonitoring: false,
  });
  const [disableScreeningType, setDisableScreeningType] = React.useState({
    initiate_screenType: false,
    individualDetails: false,
    reqSearch: false,
    targ_Date: false,
    freq: false,
    alert: false,
    bulk: false,
    ongoingMonitoring: false,
  });
  const [saveVisible, setSaveVisible] = React.useState({
    initiate_screenType: false,
    individualDetails: false,
    reqSearch: false,
    targ_Date: false,
    freq: false,
    alert: false,
    analyst: false,
    bulk: false,
    ongoingMonitoring: false,
  });

  // BULK INITIATE REQUIRED STATES
  const [previewData, setPreviewData] = React.useState(null);

  /**
   * @function fetchTeamData
   * @description A function to fetch Team Data
   * @returns {Promise} returns an axios promise
   */
  const fetchTeamData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.INITIATE_SCREEN_TEAM_DATA).then(
      (response) => {
        setIsLoading(false);
        setTeamList(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchTreeData
   * @description A function to fetch Tree Data
   * @returns {Promise} returns an axios promise
   */
  const fetchTreeData = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RULE_ENGINE_SANCTION_LIST).then(
      (response) => {
        setIsLoading(false);
        if (response?.data) {
          setSanctionList(response?.data);
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function fetchBagOfWords
   * @description A function to fetch Bag Of Words
   * @returns {Promise} returns an axios promise
   */
  const fetchBagOfWords = (data) => {
    setIsLoading(true);

    return axios.get(API_ENDPOINTS.INITIATE_SCREEN_BAG_OF_WORDS, data).then(
      (response) => {
        setIsLoading(false);
        if (response?.data) {
          setbagofwords(
            response?.data?.solytics_label?.filter((item) => item?.selected)
          );
          setGoogleAmlTaxonomy(
            response?.data?.google_label?.filter((item) => item?.selected)
          );
          setBaiduAmlTaxonomy(
            response?.data?.baidu_label?.filter((item) => item?.selected)
          );
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };
  /**
   * @function updateSanctionsFilter
   * @description A function to update Sanctions Filter
   * @returns {Promise} returns an axios promise
   */
  const updateSanctionsFilter = (data) => {
    const body = {
      date: {
        endDate: data.End_Date,
        startDate: data.Start_Date,
      },
      List: {
        list: data.list,
      },
    };
    setIsLoading(true);
    axios.post(API_ENDPOINTS.RULE_ENGINE_DELTA_CHANGE, body).then(
      (response) => {
        if (response.status === 200) {
          enqueueSnackbar(response.data.Msg, {
            variant: 'success',
          });
          setStepWiseFormData({});
          setDisableScreeningType({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            bulk: false,
            alert: false,
            ongoingMonitoring: false,
          });
          setSaveVisible({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            alert: false,
            bulk: false,
            analyst: false,
            ongoingMonitoring: false,
          });

          setVisibleScreens({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            bulk: false,
            alert: false,
            ongoingMonitoring: false,
          });
        }
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
      }
    );
  };

  useEffect(() => {
    fetchBagOfWords();
    fetchTreeData();
    fetchTeamData();
  }, []);
  /**
   * @function importBulkFile
   * @description A function to import Bulk File
   * @returns {Promise} returns an axios promise
   */
  const importBulkFile = (data, _stepsWiseFormData) => {
    const formData = new FormData();
    formData.append('upload', fileData);
    formData.append('list_type', Object.values(data));
    // formData.append('om_field', !!_stepsWiseFormData?.om_field);
    formData.append(
      'frequency',
      _stepsWiseFormData?.frequencyType === 'custom'
        ? _stepsWiseFormData?.numberOfDays
        : _stepsWiseFormData?.frequencyType
    );
    if (_stepsWiseFormData?.screeningType === 'initialScreening') {
      formData.append('screen', 'initial');
    } else {
      formData.append('screen', 'ongoing');
    }
    setIsLoading(true);

    return axios
      .post(API_ENDPOINTS.INITIATE_SCREEN_BULK_FILE_UPLOAD, formData)
      .then(
        (res) => {
          setIsLoading(false);

          setBatchId(res.data.batchID);
          setUploadData(res?.data);
          if (res?.status === 200) {
            updateCsvDisplayStatus(true);
            enqueueSnackbar('File uploaded successfully', {
              variant: 'success',
            });
          } else {
            enqueueSnackbar(
              'Please upload excel file in right format, you can also refer sample file below',
              {
                variant: 'warning',
              }
            );
          }
        },
        (e) => {
          updateCsvDisplayStatus(false);
          setIsLoading(false);
          setIsError(e);
        }
      );
  };
  /**
   * @function fetchUsers
   * @description A function to fetch Users
   * @returns {Promise} returns an axios promise
   */
  const fetchUsers = (params) => {
    setIsLoading(true);
    if (batchID !== '') {
      return axios
        .get(API_ENDPOINTS.INITIATE_SCREEN_BULK_FILE_LIST, {
          params: {
            page: params.pageIndex + 1,
            pagesize: params.pageSize,
            screen:
              stepsWiseFormData.screeningType === 'initialScreening'
                ? 'initial'
                : 'ongoing',
            batchID,
          },
        })
        .then(
          (response) => {
            setIsLoading(false);
            setCsvData(response.data);
            setTotalUsers(response?.headers['x-total-count']);
            enqueueSnackbar('Fetch data successfully', {
              variant: 'success',
              preventDuplicate: true,
            });
            return response;
          },
          (e) => {
            setIsError(e);
          }
        );
    }
  };
  /**
   * @function AlertCount
   * @description A function to get Alert Count
   * @returns {Promise} returns an axios promise
   */
  const AlertCount = useCallback(() => {
    if (batchID) {
      axios
        .get(`${API_ENDPOINTS.INITIATE_SCREEN_BULK_ALERT_COUNT}${batchID}/`)
        .then(
          (response) => {
            setCount(response.data);
          },
          () => {}
        );
    }
  }, [stepsWiseFormData, batchID]);

  /**
   * Initiates a final screening with the provided data and values.
   *
   * @param {Object} data - The step-wise form data.
   * @param {string} values - The type of screening to initiate ('manual', 'count', or 'percentage').
   * @returns {Promise} A Promise that resolves when the screening is initiated.
   */
  const finalCall = (data, values, crrCustomParams) => {
    const body = {
      ...crrCustomParams,
      team: values === 'manual' ? undefined : data.selectTeam,
      target_date: moment(data.targetDate).format('YYYY-MM-DD'),
      manualAssign: values === 'manual',
      screen_type:
        data?.screeningType === 'initialScreening' ? 'initial' : 'ongoing',
      name: data.name,
      gender: data.gender,
      date_of_birth: data.dob,
      end_date: data?.end_date,
      national_id: data.national_id,
      passport_number: data.passport_number,
      voters_id: data.voters_id,
      drivers_license: data.drivers_license,
      nationality: data.nationality,
      country_of_jurisdiction: data.country_of_jurisdiction,
      address: data?.address,
      zipcode: data?.zipcode,
      city: data?.city,
      state: data?.state,
      pan: data?.pan === '' && data?.tin !== '' ? data?.tin : data?.pan,
      tin: data?.tin === '' && data?.pan !== '' ? data?.pan : data?.tin,
      aliases: data?.aliases,
      isin: data?.isin,
      cin: data?.cin,
      gst: data?.gst,
      headquarter: data?.headquarters,
      country: data?.country,
      registered_owner: data?.registered_owner,
      parent_company: data?.parent_company,
      phone_number: data?.phone_number,
      email: data?.email,
      vessel_type: data?.vessel_type,
      imo: data?.imo,
      mmsi: data?.mmsi,
      flag_state: data?.flag_state,
      operator: data?.operator,
      manufacture_serial_number: data?.manufacture_serial_number,
      construction_number: data?.construction_number,
      program: data.program,
      aircraft_model: data.aircraft_model,
      tonnage: data?.tonnage,
      pep_field: !!data.pep_field,
      ams_field: !!data.ams_field,
      sanction_field: !!data.sanction_field,
      legal_field: !!data.legal_field,
      corporate_field: !!data.corporate_field,
      // om_field: !!data.om_field,
      search_type: data.search_type,
      list: data.list?.length ? data.list : sanctionList,
      bag_of_words:
        data.bag_of_words?.length === 0
          ? bagofwords.map((item) => item.uid)
          : data.bag_of_words,
      frequency: 0,
      risk_rating_field: data?.risk_rating_field,
      income: data?.income !== '' ? Number(data?.income) : null,
      product: data?.product_type,
      onboarding_duration:
        data?.onboarding_duration !== ''
          ? Number(data?.onboarding_duration)
          : null,
      onboarding_type: data?.onboarding_type,
      missed_payment:
        data?.missed_payment !== '' ? Number(data?.missed_payment) : null,
      ...((data?.search_type === 'i' || data?.search_type === 'b') && {
        age: data?.age !== '' ? Number(data?.age) : null,
        occupation: data?.occupation,
        new_occupation: data?.new_occupation,
      }),
      ...((data?.search_type === 'e' || data?.search_type === 'b') && {
        industry: data?.industry,
        incorporation: Number(data?.incorporation),
      }),
      ...(data?.review_id && {
        review_id: data?.review_id,
      }),
      ...(data?.previous_search_id && {
        previous_search_id: data?.previous_search_id,
      }),
      rule_id: data?.rule_id,
      ssn: data?.ssn,
      applicant_id: data?.applicant_id,
      application_id: data?.application_id,
      sdfc_lead_id: data?.sdfc_lead_id,
      branch_name: data?.branch_name,
      product_line: data?.product_line,
      pennant_analyst_name: data?.pennant_analyst_name,
      analyst_email_id: data?.analyst_email_id,
      other_fields: data?.other_fields,
      google_search: !!data?.search_engine?.includes('google_search'),
      baidu_search: !!data?.search_engine?.includes('baidu_search'),
      solytics_db_search: !!data?.search_engine?.includes('solytics_db_search'),
      ams_taxonomy: {
        baidu_taxonomy: data?.baidu_aml_taxonomy,
        google_taxonomy: data?.google_aml_taxonomy,
        solytics_taxonomy: data?.bag_of_words,
      },
      from_date: data?.from_data,
      to_data: data?.to_data,
      // ...((data?.screeningType !== 'initialScreening' || !!data.om_field) && {
      //   frequency: data?.frequencyType
      //     ? data?.frequencyType === 'custom'
      //       ? data?.numberOfDays
      //       : data?.frequencyType
      //     : data?.OngoingfrequencyType === 'custom'
      //     ? data?.OngoingfrequencyDays
      //     : data?.OngoingfrequencyType,
      // }),
    };
    setIsLoading(true);
    return axios.post(API_ENDPOINTS.INITIATE_SCREEN_INITIAL_INDI, body).then(
      () => {
        setIsLoading(false);
        enqueueSnackbar('Search initiated successfully', {
          variant: 'success',
        });
        setStepWiseFormData({});
        setDisableScreeningType({
          initiate_screenType: false,
          individualDetails: false,
          reqSearch: false,
          targ_Date: false,
          freq: false,
          bulk: false,
          alert: false,
          ongoingMonitoring: false,
        });
        setSaveVisible({
          initiate_screenType: false,
          individualDetails: false,
          reqSearch: false,
          targ_Date: false,
          freq: false,
          bulk: false,
          alert: false,
          analyst: false,
          ongoingMonitoring: false,
        });
        setVisibleScreens({
          initiate_screenType: false,
          individualDetails: false,
          reqSearch: false,
          targ_Date: false,
          freq: false,
          bulk: false,
          alert: false,
          ongoingMonitoring: false,
        });
      },
      () => {
        setIsLoading(false);
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
        });
      }
    );
  };
  /**
   * @function transactionFinalCall
   * @description A function to perform the  transaction
   * @param {Object} data - The step-wise form data.
   * @param {string} values - The type of screening to initiate ('manual', 'count', or 'percentage').
   * @returns {Promise} returns an axios promise
   */
  const transactionFinalCall = (data, values) => {
    const body = {
      screen_type:
        data?.screeningType === 'initialScreening' ? 'initial' : 'ongoing',
      sanction_field: !!data.sanction_field,
      pep_field: !!data.pep_field,
      ams_field: !!data.ams_field,
      legal_field: !!data.legal_field,
      corporate_field: !!data.corporate_field,
      risk_rating_field: !!data.risk_rating_field,
      target_date: moment(data.targetDate).format('YYYY-MM-DD'),
      end_date: data?.end_date,
      team: values === 'manual' ? undefined : String(data.selectTeam),
      manualAssign: values === 'manual',
      name: data.name,
      gender: data.gender,
      date_of_birth: data.dob,
      national_id: data.ssn,
      passport_number: data.passport_number,
      voters_id: data.voters_id,
      drivers_license: data.drivers_license,
      nationality: data.nationality,
      country_of_jurisdiction: data.country_of_jurisdiction,
      address: data.address,
      zipcode: data.zipcode,
      city: data?.city,
      state: data?.state,
      pan: data?.pan === '' && data?.tin !== '' ? data?.tin : data?.pan,
      tin: data?.tin === '' && data?.pan !== '' ? data?.pan : data?.tin,
      aliases: data.aliases,
      isin: data.isin,
      cin: data.cin,
      gst: data.gst,
      headquarter: data.headquarters,
      country: data.country,
      registered_owner: data.registered_owner,
      parent_company: data.parent_company,
      phone_number: data.phone_number,
      email: data.email,
      vessel_type: data.vessel_type,
      imo: data.imo,
      mmsi: data.mmsi,
      flag_state: data.flag_state,
      operator: data.operator,
      manufacture_serial_number: data.manufacture_serial_number,
      construction_number: data.construction_number,
      program: data.program,
      aircraft_model: data.aircraft_model,
      // om_field: !!data.om_field,
      list: data.list?.length ? data.list : sanctionList,
      bag_of_words:
        data.bag_of_words?.length === 0
          ? bagofwords.map((item) => item.label)
          : data.bag_of_words,
      ...(data?.review_id && {
        review_id: data?.review_id,
      }),
      ...(data?.previous_search_id && {
        previous_search_id: data?.previous_search_id,
      }),
      amount: data?.amount,
      transaction_date: data?.transaction_date,
      transaction_id: data?.transaction_id,
      product: data?.product,
      transaction_type: data?.transaction_type,
      channel: data?.channel,
      currency: data?.currency,
      payer_details: {
        ...data?.payer_details,
        age:
          data?.payer_details?.age !== ''
            ? Number(data?.payer_details?.age)
            : null,
        occupation: data?.payer_details?.occupation,
        new_occupation: data?.payer_details?.new_occupation,
        income:
          data?.payer_details?.income !== ''
            ? Number(data?.payer_details?.income)
            : null,
        product: data?.payer_details?.product_type,
        onboarding_duration:
          data?.payer_details?.onboarding_duration !== ''
            ? Number(data?.payer_details?.onboarding_duration)
            : null,
        onboarding_type: data?.payer_details?.onboarding_type,
        missed_payment:
          data?.payer_details?.missed_payment !== ''
            ? Number(data?.payer_details?.missed_payment)
            : null,
        industry: data?.payer_details?.industry,
        incorporation: data?.payer_details?.incorporation
          ? Number(data?.payer_details?.incorporation)
          : null,
      },
      recipient_details: {
        ...data?.recipient_details,
        age:
          data?.recipient_details?.age !== ''
            ? Number(data?.recipient_details?.age)
            : null,
        occupation: data?.recipient_details?.occupation,
        new_occupation: data?.recipient_details?.new_occupation,
        income:
          data?.recipient_details?.income !== ''
            ? Number(data?.recipient_details?.income)
            : null,
        product: data?.recipient_details?.product_type,
        onboarding_duration:
          data?.recipient_details?.onboarding_duration !== ''
            ? Number(data?.recipient_details?.onboarding_duration)
            : null,
        onboarding_type: data?.recipient_details?.onboarding_type,
        missed_payment:
          data?.recipient_details?.missed_payment !== ''
            ? Number(data?.recipient_details?.missed_payment)
            : null,
        industry: data?.recipient_details?.industry,
        incorporation:
          data?.recipient_details?.incorporation !== ''
            ? Number(data?.recipient_details?.incorporation)
            : null,
      },
      rule_id: data?.rule_id,
      google_search: !!data?.search_engine?.includes('google_search'),
      baidu_search: !!data?.search_engine?.includes('baidu_search'),
      solytics_db_search: !!data?.search_engine?.includes('solytics_db_search'),
      ams_taxonomy: {
        baidu_taxonomy: data?.baidu_aml_taxonomy,
        google_taxonomy: data?.google_aml_taxonomy,
        solytics_taxonomy: data?.bag_of_words,
      },
      from_date: data?.from_data,
      to_data: data?.to_data,
    };

    setIsLoading(true);

    // Use the custom instance to make the API call
    return Utils.CoreUtils.customAxiosForFastApi
      .post(API_ENDPOINTS.TRANSACTION_FINAL_CALL, body)
      .then(
        () => {
          setIsLoading(false);
          enqueueSnackbar('Search initiated successfully', {
            variant: 'success',
          });
          setStepWiseFormData({});
          setDisableScreeningType({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            bulk: false,
            alert: false,
            ongoingMonitoring: false,
          });
          setSaveVisible({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            bulk: false,
            alert: false,
            analyst: false,
            ongoingMonitoring: false,
          });
          setVisibleScreens({
            initiate_screenType: false,
            individualDetails: false,
            reqSearch: false,
            targ_Date: false,
            freq: false,
            bulk: false,
            alert: false,
            ongoingMonitoring: false,
          });
        },
        (res) => {
          setIsLoading(false);
          if (res?.response?.data?.detail?.message) {
            enqueueSnackbar(res?.response?.data?.detail?.message, {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('Something went wrong', {
              variant: 'error',
            });
          }
        }
      );
  };

  /**
   * @function getRiskParameters
   * @description A function to get risk parameters
   * @returns {Promise} returns an axios promise
   */
  const getRiskParameters = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.RISK_PARAMETERS).then(
      (response) => {
        setIsLoading(false);
        setRiskParameters(response?.data);
        setIndustry(response?.data?.['Industry Type']?.values);
        setProduct(response?.data?.['Product Type']?.values);
        setOccupation(response?.data?.Occupation?.values);
        setCrrMandatoryParameterList(response?.data?.crr_mandatory_fields);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };
  /**
   * @function getTransactionParameters
   * @description A function to get transaction parameters
   * @returns {Promise} returns an axios promise
   */
  const getTransactionParameters = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.TRANSACTION_PARAMETERS, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          response?.data?.data?.map((item) => {
            if (item?.name === 'channel_type') {
              setChannel({ values: item?.values, mandatory: item?.mandatory });
            }
            if (item?.name === 'transaction_type') {
              setTransactionType({
                values: item?.values,
                mandatory: item?.mandatory,
              });
            }
            if (item?.name === 'transaction_product_type') {
              setProductType({
                values: item?.values,
                mandatory: item?.mandatory,
              });
            }
          });
          setTransactionResponse(response?.data?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };

  /**
   * @function fetchRules
   * @description A function to fetch all rules IDS
   * @returns {Promise} returns an axios promise
   */
  const fetchRules = () => {
    setIsLoading(true);
    return axios.get(API_ENDPOINTS.ALL_RULE_IDS).then(
      (response) => {
        setIsLoading(false);
        setAllRules(response?.data);
      },
      (e) => {
        setIsLoading(false);
        setIsError(e);
        throw e;
      }
    );
  };

  /**
   * @function getRecipientTransactionParameters
   * @description A function to get recipient transaction parameters
   * @returns {Promise} returns an axios promise
   */
  const getRecipientTransactionParameters = (queryParams) => {
    setIsLoading(true);
    return axios
      .get(API_ENDPOINTS.TRANSACTION_PARAMETERS, {
        params: queryParams,
      })
      .then(
        (response) => {
          setIsLoading(false);
          setRecipientTransactionResponse(response?.data?.data);
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
          throw e;
        }
      );
  };
  const finalCallForInitiateBulk = (data) => {
    const body = {
      assign_type: data.alert
        ? data.nonAlert
          ? 'b'
          : 'a'
        : data.nonAlert
        ? 'na'
        : 'n',
    };
    return axios
      .post(`${API_ENDPOINTS.INITIATE_SCREEN_BULK_ASSIGN}${batchID}/`, body)
      .then(
        (res) => {
          if (res?.status === 200) {
            setCsvData([]);
            setBatchId('');
            setUploadData({});
            setStepWiseFormData({});
            setDisableScreeningType({
              initiate_screenType: false,
              individualDetails: false,
              reqSearch: false,
              targ_Date: false,
              freq: false,
              bulk: false,
              alert: false,
              ongoingMonitoring: false,
            });
            setSaveVisible({
              initiate_screenType: false,
              individualDetails: false,
              reqSearch: false,
              targ_Date: false,
              freq: false,
              bulk: false,
              alert: false,
              analyst: false,
              ongoingMonitoring: false,
            });

            setVisibleScreens({
              initiate_screenType: false,
              individualDetails: false,
              reqSearch: false,
              targ_Date: false,
              freq: false,
              bulk: false,
              alert: false,
              ongoingMonitoring: false,
            });
            setfileData(undefined);
            setListType('i');
            updateCsvDisplayStatus(false);
            enqueueSnackbar('Search assigned successfully', {
              variant: 'success',
            });
            setCount({ alert: 0, non_alert: 0, progress: 0, status: 'p' });
            return res;
          }
        },
        (err) => {
          throw err;
        }
      );
  };
  /**
   * Initiates an email for the given screening.
   *
   * @param {string} screening - The screening type.
   */
  const emailInitiate = (screening) => {
    const body = {
      screening,
      batchID: uploadData.batchID,
    };
    axios.post(API_ENDPOINTS.INITIATE_SCREEN_EMAIL, body).then(
      (res) => res,
      (e) => {
        throw e;
      }
    );
  };
  /**
   * Initiates a final bulk screening with the provided data and values.
   *
   * @param {Object} data - The step-wise form data.
   * @param {string} values - The type of bulk screening to initiate ('manual', 'count', or 'percentage').
   * @returns {Promise} A Promise that resolves when the screening is initiated.
   */
  const initiateFinalBulk = (data, values) => {
    const payload = {
      screen_type: stepsWiseFormData?.screeningType?.startsWith('i')
        ? 'i'
        : 'o',
      size: uploadData?.size,
      upload_id: uploadData?.upload_id,
      // om_field: !!stepsWiseFormData?.om_field,
      search_type: uploadData?.search_type, // individual or entity or vessel or aircraft
      // ...((!stepsWiseFormData?.screeningType?.startsWith('i') ||
      //   stepsWiseFormData.om_field) && {
      //   frequency: data?.frequencyType
      //     ? data?.frequencyType === 'custom'
      //       ? data?.numberOfDays
      //       : data?.frequencyType
      //     : data?.OngoingfrequencyType === 'custom'
      //     ? data?.OngoingfrequencyDays
      //     : data?.OngoingfrequencyType,
      // }),
      sanction_field: !!stepsWiseFormData?.sanction_field,
      pep_field: !!stepsWiseFormData?.pep_field,
      ams_field: !!stepsWiseFormData?.ams_field,
      legal_field: !!stepsWiseFormData?.legal_field,
      corporate_field: !!stepsWiseFormData?.corporate_field,
      bag_of_words:
        data.bag_of_words.length === 0
          ? bagofwords.map((item) => item.uid)
          : data.bag_of_words,
      risk_rating_field: data?.risk_rating_field,
      income: data?.income !== '' ? data?.income : null,
      product: data?.product_type,
      onboarding_duration:
        data?.onboarding_duration !== '' ? data?.onboarding_duration : null,
      ...(uploadData?.search_type === 'i' && {
        age: data?.age !== '' ? data?.age : null,
        occupation: data?.occupation,
        new_occupation: data?.new_occupation,
      }),
      ...(uploadData?.search_type === 'e' && {
        industry: data?.industry,
        incorporation: data?.incorporation,
      }),
      rule_id: data?.rule_id,
    };
    switch (values) {
      case 'manual':
        // Add Manual Field in payload
        break;
      case 'count':
        payload.search_distribution = stepsWiseFormData?.tableData?.map(
          (team) => ({
            team_id: team?.selectTeam,
            count: team?.searchesAssigned,
            target_date: team?.targetDate,
          })
        );
        break;
      case 'percentage': {
        const total = uploadData?.size;
        payload.search_distribution = stepsWiseFormData?.tableData?.map(
          (team) => ({
            team_id: team?.selectTeam,
            count: Math.round((team?.searchesAssigned / 100) * total),
            target_date: team?.targetDate,
          })
        );
        break;
      }
      default:
        break;
    }
    setIsLoading(true);
    return axios
      .post(
        data?.screeningType === 'initialScreening'
          ? API_ENDPOINTS.INITIATE_SCREEN_FINAL_BULK_CALL_INITIAL
          : API_ENDPOINTS.INITIATE_SCREEN_FINAL_BULK_CALL_ONGOING,
        payload
      )
      .then(
        (res) => {
          setIsLoading(false);
          Utils.CoreUtils.autoScroll();
          enqueueSnackbar(`Searches initiated successfully`, {
            variant: 'success',
          });
          setBatchId(res.data.batch_id);
          setVisibleScreens({
            ...visibleScreens,
            alert: true,
          });
          setDisableScreeningType({
            ...disableScreeningType,
            targ_Date: true,
          });
          setSaveVisible({
            ...saveVisible,
            targ_Date: true,
          });
          setCount(() => ({
            alert: 0,
            non_alert: 0,
            progress: 0,
            status: 'p',
          }));
        },
        (e) => {
          setIsLoading(false);
          setIsError(e);
        }
      );
  };

  // MULTI PART UPLOAD
  const initiateFileUpload = (fileDetails, successCallback, errorCallback) => {
    axios.post(API_ENDPOINTS.MULTIPART_UPLOAD_START_UPLOAD, fileDetails).then(
      (resp) => {
        if (successCallback) {
          successCallback(resp);
        }
      },
      (error) => {
        errorCallback(error);
      }
    );
  };
  /**
   * Fetches presigned URLs for each chunk of a multipart upload.
   *
   * @param {Object} chunkDetails - Details about the file chunks.
   * @returns {Promise} A Promise that resolves with the presigned URLs.
   */
  const fetchPresignedUrlForEachChunk = (chunkDetails) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_GET_PRESIGNED_URL, chunkDetails)
      .then();

  /**
   * Completes a multipart upload for a file with the given summary.
   *
   * @param {Object} fileChunksSummary - Summary of the file chunks.
   * @param {Function} successCallback - Callback function to handle success.
   * @param {Function} errorCallback - Callback function to handle errors.
   */
  const onCompleteMultipartUpload = (
    fileChunksSummary,
    successCallback,
    errorCallback
  ) =>
    axios
      .post(API_ENDPOINTS.MULTIPART_UPLOAD_COMPLETE, {
        ...fileChunksSummary,
      })
      .then(
        (res) => {
          successCallback(res);
        },
        (error) => {
          errorCallback(error);
        }
      );
  /**
   * Deletes the uploaded file.
   *
   * @returns {Promise} A Promise that resolves when the file is deleted.
   */
  const deleteFile = () => {
    setIsLoading(true);
    if (uploadData?.upload_id) {
      return axios
        .post(API_ENDPOINTS.MULTIPART_UPLOAD_DELETE, {
          uploadId: uploadData?.upload_id,
        })
        .then(
          (res) => {
            setIsLoading(true);
            return res;
          },
          (e) => {
            setIsLoading(false);
            setIsError(e);
          }
        );
    }
  };
  const { startUpload, progress } = useMultipartFileUploadToS3({
    startUploadCallback: initiateFileUpload,
    fetchPresignedUrlForEachChunk,
    onCompleteMultipartUpload,
  });

  return {
    user,
    isError,
    isLoading,
    bagofwords,
    importBulkFile,
    fileData,
    csvData,
    fetchBagOfWords,
    isCsvLoading,
    setfileData,
    updateCsvDisplayStatus,
    finalCall,
    emailInitiate,
    finalCallForInitiateBulk,
    AlertCount,
    count,
    visibleScreens,
    setVisibleScreens,
    stepsWiseFormData,
    setStepWiseFormData,
    disableScreeningType,
    setDisableScreeningType,
    saveVisible,
    setSaveVisible,
    sanction,
    setSanction,
    sanctionList,
    selectedList,
    setSelectedList,
    updateSanctionsFilter,
    bulkBagOfWords,
    setBulkBagOfWords,
    fetchUsers,
    batchID,
    setCsvData,
    setCheckerLevels,
    checkerLevel,
    initiateFinalBulk,
    setTotalUsers,
    totalUsers,
    teamList,
    startUpload,
    progress,
    deleteFile,
    previewData,
    setPreviewData,
    setUploadData,
    getRiskParameters,
    industry,
    product,
    occupation,
    listType,
    setListType,
    countryList,
    fetchCountryList,
    crrMandatoryParameterList,
    getTransactionParameters,
    channel,
    transactionType,
    productType,
    transactionResponse,
    getRecipientTransactionParameters,
    recipientTransactionResponse,
    fetchRules,
    allRules,
    transactionFinalCall,
    googleAmlTaxonomy,
    baiduAmlTaxonomy,
    riskParameters,
  };
};
export default useInitiate;
