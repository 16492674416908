// @ts-nocheck
/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { StyledEngineProvider } from '@mui/material';
import { useSnackbar } from 'notistack';
import { Utils } from './utils/index';
import useCurrentTheme from './theme/useCurrentTheme';
import { initializeAxiosForKeycloak } from './utils/ApiKeycloak';
import { AppRoutes } from './routing/AppRoutes';
import { API_ENDPOINTS } from './const/ApiEndpoints';
// import keycloakConfig from './keycloak-config';
import { setAuth, setPanelNavigation } from 'state/slices/appSlice';
import { useSelector, useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';
import { CoreUtils } from 'utils/Utils';

// const initKeycloak = async () => {
//   return keycloakConfig
//     .init({
//       onLoad: 'login-required',
//       KeycloakResponseType: 'code',
//       silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
//       checkLoginIframe: false,
//       pkceMethod: 'S256',
//     })
//     .then((auth) => {
//       if (!auth) {
//         window.location.reload();
//       } else {
//         setTimeout(() => {
//           document.dispatchEvent(
//             new CustomEvent('KEYCLOAK_LOGIN_SUCCESS', { detail: { auth } })
//           );
//         }, 1000);
//       }
//       return auth;
//     });
// };

if (process.env.REACT_APP_KEYCLOAK_LOGIN === 'true') {
  if (!CoreUtils.isValueEmpty(localStorage.getItem('REALM_ID'))) {
    if (CoreUtils.isValueEmpty(localStorage.getItem('token'))) {
      CoreUtils.initKeycloak();
      initializeAxiosForKeycloak();
    } else {
      CoreUtils.initKeycloak();
      initializeAxiosForKeycloak();
    }
  }
} else {
  Utils.ApiUtils.intializeAxios();
}

function App({ notistackRef }) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const dispatch = useDispatch();
  const { fetchPanelInfo, panelData } = useAuth();
  const { user } = useSelector((state) => state);
  const [loading, setLoading] = useState(true);
  const [panelInfoData, setPanelInfoData] = useState({});

  const theme = useCurrentTheme();
  const handleKeycloakEvent = React.useCallback(
    (event) => {
      const { keycloakConfig } = event.detail;

      const keycloakUserData = keycloakConfig.tokenParsed;
      const userData = {
        username: keycloakUserData.preferred_username,
        email: keycloakUserData.email,
        id: keycloakUserData.sub,
      };

      // Dispatch everything except the user info first
      dispatch(
        setAuth({
          isLoggedIn: keycloakConfig.authenticated,
          token: keycloakConfig.token,
          refresh: keycloakConfig.refreshToken,
          org: keycloakConfig.realm,
          user: null, // Set user as null or undefined initially
        })
      );

      // Fetch panel info and then update user info in the auth state
      fetchPanelInfo().then((data) => {
        setPanelInfoData(data);

        dispatch(
          setPanelNavigation({
            panelNavigation: data?.panel_navigation,
          })
        );

        // Update the auth state with user info after panel info is fetched
        dispatch(
          setAuth({
            isLoggedIn: keycloakConfig.authenticated,
            token: keycloakConfig.token,
            refresh: keycloakConfig.refreshToken,
            org: keycloakConfig.realm,
            user: data?.user, // Update the user information
          })
        );
        setLoading(false);

        if (window.location?.pathname === '/org') {
          window.location = '/manager-dashboard';
        }
      });
    },
    [dispatch, setAuth, setLoading, setPanelNavigation, history]
  );

  useEffect(() => {
    document.addEventListener('KEYCLOAK_LOGIN_SUCCESS', handleKeycloakEvent);
    if (process.env.REACT_APP_KEYCLOAK_LOGIN !== 'true') {
      return () => {
        document.removeEventListener(
          'KEYCLOAK_LOGIN_SUCCESS',
          handleKeycloakEvent
        );
      };
    }
  }, []);
  useEffect(() => {
    // @ts-ignore
    window.enqueueSnackbar = notistackRef.current.enqueueSnackbar;
  }, []);
  const isLoggedIn = useSelector((state) => state?.app?.auth?.isLoggedIn);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <AppRoutes
          loading={loading}
          isLoggedIn={
            process.env.REACT_APP_KEYCLOAK_LOGIN === 'true'
              ? localStorage.getItem('isLoggedIn')
              : isLoggedIn
          }
        />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
