const init = {
  name: '',
  gender: '',
  dob: null,
  ssn: '',
  passport_number: '',
  voter_id: '',
  driving_license: '',
  nationality: '',
  country_of_jurisdiction: '',
  address: [''],
  pan: '',
  aliases: '',
  tin: '',
  phone_number: '',
  email: '',
  date_of_establishment: '',
  headquarters: '',
  isin: '',
  cin: '',
  gst: '',
  vessel_type: '',
  manufacture_date: '',
  country: [''],
  flag_state: '',
  parent_company: '',
  tonnage: '',
  registered_owner: '',
  imo: '',
  mmsi: '',
  aircraft_operator: '',
  manufacture_serial_number: '',
  program: '',
  aircraft_model: '',
  operator: '',
  construction_number: '',
  zip_code: '',
  age: '',
  income: '',
  industry: '',
  incorporation: '',
  occupation: '',
  product_type: '',
  onboarding_duration: '',
  new_occupation: '',
  end_date: null,
  applicant_id: '',
  application_id: '',
  sdfc_lead_id: '',
  branch_name: '',
  product_line: '',
  pennant_analyst_name: '',
  analyst_email_id: '',
  other_fields: {},
};

const transactioninit = {
  transaction_id: '',
  transaction_type: '',
  channel: '',
  product: '',
  amount: '',
  transaction_date: null,
  currency: '',
  end_date: null,
  payer_details: {
    search_type: 'b',
    name: '',
    gender: '',
    date_of_birth: null,
    ssn: '',
    passport_number: '',
    driving_license: '',
    nationality: '',
    country_of_jurisdiction: '',
    address: '',
    pan: '',
    aliases: '',
    national_id: '',
    tin: '',
    phone_number: '',
    email: '',
    headquarter: '',
    isin: '',
    cin: '',
    gst: '',
    country: '',
    zip_code: '',
    kycID: '',
    vessel_type: '',
    program: '',
    flag_state: '',
    operator: '',
    manufacture_serial_number: '',
    mmsi: '',
    voters_id: '',
    registered_owner: '',
    tonnage: '',
    aircraft_model: '',
    imo: '',
    construction_number: '',
    parent_company: '',
    end_date: '',
    account_number: '',
    ifsc_code: '',
    bank_name: '',
    age: '',
    income: '',
    industry: '',
    incorporation: '',
    occupation: '',
    product_type: '',
    onboarding_duration: '',
    new_occupation: '',
    missed_payment: '',
  },
  recipient_details: {
    search_type: 'b',
    name: '',
    gender: '',
    date_of_birth: null,
    ssn: '',
    passport_number: '',
    driving_license: '',
    nationality: '',
    country_of_jurisdiction: '',
    address: '',
    pan: '',
    aliases: '',
    national_id: '',
    tin: '',
    phone_number: '',
    email: '',
    headquarter: '',
    isin: '',
    cin: '',
    gst: '',
    country: '',
    zip_code: '',
    kycID: '',
    vessel_type: '',
    program: '',
    flag_state: '',
    operator: '',
    manufacture_serial_number: '',
    mmsi: '',
    voters_id: '',
    registered_owner: '',
    tonnage: '',
    aircraft_model: '',
    imo: '',
    construction_number: '',
    parent_company: '',
    end_date: '',
    account_number: '',
    ifsc_code: '',
    bank_name: '',
    age: '',
    income: '',
    industry: '',
    incorporation: '',
    occupation: '',
    product_type: '',
    onboarding_duration: '',
    new_occupation: '',
    missed_payment: '',
  },
};

const vessels = [
  { value: 'dry cargo ships', label: 'Dry Cargo Ships' },
  { value: 'bulk carriers', label: 'Bulk Carriers' },
  { value: 'general cargo vessels', label: 'General Cargo Vessels' },
  { value: 'container vessels', label: 'Container Vessels' },
  { value: 'reefer vessels', label: 'Reefer Vessels' },
  { value: 'ro-ro Vessels', label: 'Ro-Ro Vessels' },
  { value: 'liquid cargo ships', label: 'Liquid Cargo Ships' },
  { value: 'crude carriers', label: 'Crude Carriers' },
  { value: 'chemical carriers', label: 'Chemical Carriers' },
  { value: 'liquefied gas carriers', label: 'Liquefied Gas Carriers' },
  { value: 'passenger vessels', label: 'Passenger Vessels' },
  { value: 'liquefied gas carriers', label: 'Liquefied Gas Carriers' },
  { value: 'tugs', label: 'Tugs' },
];

const programs = [
  { value: 'ALL', label: 'All' },
  { value: 'BALKANS', label: 'BALKANS' },
  { value: 'BELARUS', label: 'BELARUS' },
  { value: '561-Related', label: '561-Related' },
  { value: 'BURUNDI', label: 'BURUNDI' },
  { value: 'CAATSA - IRAN', label: 'CAATSA - IRAN' },
  { value: 'CAATSA - RUSSIA', label: 'CAATSA - RUSSIA' },
  { value: 'CAR', label: 'CAR' },
  { value: 'CUBA', label: 'CUBA' },
  { value: 'CYBER2', label: 'CYBER2' },
  { value: 'DARFUR', label: 'DARFUR' },
  { value: 'DPRK', label: 'DPRK' },
  { value: 'DPRK2', label: 'DPRK2' },
  { value: 'DPRK3', label: 'DPRK3' },
  { value: 'DPRK4', label: 'DPRK4' },
  { value: 'DPRK-NKSPEA', label: 'DPRK-NKSPEA' },
  { value: 'DRCONGO', label: 'DRCONGO' },
  { value: 'ELECTION-EO13848', label: ' ELECTION-EO13848 ' },
  { value: 'FSE-IR', label: 'FSE-IR' },
  { value: 'FSE-SY', label: 'FSE-SY' },
  { value: 'FTO', label: 'FTO' },
  { value: 'GLOMAG', label: 'GLOMAG' },
  { value: 'HIFPAA', label: 'HIFPAA' },
  { value: 'HK-EO13936', label: 'HK-EO13936' },
  { value: 'HRIT-IR', label: 'HRIT-IR' },
  { value: 'HRIT-SY', label: 'HRIT-SY' },
  { value: 'ICCP-EO13928', label: 'ICCP-EO13928' },
  { value: 'IFCA', label: 'IFCA' },
  { value: 'IFSR', label: 'IFSR' },
  { value: 'IRAN', label: 'IRAN' },
  { value: 'IRAN-CON-ARMS-EO', label: ' IRAN-CON-ARMS-EO' },
  { value: 'IRAN-EO13846', label: 'IRAN-EO13846' },
  { value: 'IRAN-EO13871', label: 'IRAN-EO13871' },
  { value: 'IRAN-EO13876', label: 'IRAN-EO13876' },
  { value: 'IRAN-EO13902', label: 'IRAN-EO13902' },
  { value: 'IRAN-HR', label: 'IRAN-HR' },
  { value: 'IRAN-TRA', label: 'IRAN-TRA' },
  { value: 'IRAQ2', label: 'IRAQ2' },
  { value: 'IRAQ3', label: 'IRAQ3' },
  { value: 'IRGC', label: 'IRGC' },
  { value: 'ISA', label: 'ISA' },
  { value: 'LEBANON', label: 'LEBANON' },
  { value: 'LIBYA2', label: 'LIBYA2' },
  { value: 'LIBYA3', label: 'LIBYA3' },
  { value: 'MAGNIT', label: 'MAGNIT' },
  { value: 'MALI-EO13882', label: 'MALI-EO13882' },
  { value: 'NICARAGUA', label: 'NICARAGUA' },
  { value: 'NICARAGUA-NHRAA', label: ' NICARAGUA-NHRAA ' },
  { value: 'NPWMD', label: 'NPWMD' },
  { value: 'NS-PLC', label: 'NS-PLC' },
  { value: 'SDGT', label: 'SDGT' },
  { value: 'SDNT', label: 'SDNT' },
  { value: 'SDNTK', label: 'SDNTK' },
  { value: 'SOMALIA', label: 'SOMALIA' },
  { value: 'SOUTH SUDAN', label: 'SOUTH SUDAN' },
  { value: 'SYRIA', label: 'SYRIA' },
  { value: 'SYRIA-CAESAR', label: 'SYRIA-CAESAR' },
  { value: 'SYRIA-EO13894', label: 'SYRIA-EO13894' },
  { value: 'TCO', label: 'TCO' },
  { value: 'UKRAINE-EO13660', label: 'UKRAINE-EO13660' },
  { value: 'UKRAINE-EO13661', label: ' UKRAINE-EO13661 ' },
  { value: 'UKRAINE-EO13662', label: 'UKRAINE-EO13662 ' },
  { value: 'UKRAINE-EO13685', label: ' UKRAINE-EO13685' },
  { value: 'VENEZUELA', label: 'VENEZUELA' },
  { value: 'VENEZUELA-EO13850', label: 'VENEZUELA-EO13850' },
  { value: 'VENEZUELA-EO13884', label: 'VENEZUELA-EO13884 ' },
  { value: 'ZIMBABWE', label: 'ZIMBABWE' },
  { value: 'YEMEN', label: 'YEMEN' },
];
const teamInitialValue = {
  name: '',
  manager: '',
  branch: '',
  product: '',
  maker: '',
  teamType: '',
  analystLevels: 1,
  analystsRank0: [],
  analystsRank1: [],
  analystsRank2: [],
  analystsRank3: [],
  analystsRank4: [],
  checkerLevels: 0,
  checker1: '',
  checker2: '',
  checker3: '',
  checker4: '',
  checker5: '',
  description: '',
};

const genders = [
  { value: 'male', label: 'Male' },
  { value: 'female', label: 'Female' },
];

const FormType = [
  { value: 'individual', label: 'Individual' },
  { value: 'entity', label: 'Entity' },
  { value: 'vessel', label: 'Vessel' },
  { value: 'aircraft', label: 'Aircraft' },
];
const TeamDataForEdit = [
  { value: 'id', label: 'Team ID' },
  { value: 'name', label: 'Team Name' },
  { value: 'branch', label: 'Branch' },
  { value: 'product', label: 'Product' },
];
const TeamData = [
  { value: 'name', label: 'Team Name' },
  { value: 'branch', label: 'Branch' },
  { value: 'product', label: 'Product' },
];

const checkerLevels = [
  { value: 0, label: 'No checker' },
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' },
  { value: 4, label: 'Level 4' },
  { value: 5, label: 'Level 5' },
];
const analystLevels = [
  { value: 1, label: 'Level 1' },
  { value: 2, label: 'Level 2' },
  { value: 3, label: 'Level 3' },
  { value: 4, label: 'Level 4' },
  { value: 5, label: 'Level 5' },
];

const availableTeams = [
  { value: 'Team1', label: 'Team 1' },
  { value: 'Team2', label: 'Team 2' },
  { value: 'Team3', label: 'Team 3' },
  { value: 'Team4', label: 'Team 4' },
  { value: 'Team5', label: 'Team 5' },
];
const sortOptions = [
  { label: 'SAMS Score', value: 'samsScore' },
  { label: 'Name Score', value: 'nameScore' },
  { label: 'DOB Score', value: 'dobScore' },
  { label: 'Reset', value: 'reset' },
];
const typeOfReport = [
  { value: 'SAR', label: 'SAR' },
  { value: 'STR', label: 'STR' },
];
const fieldTypes = [
  { value: 'text', label: 'Text' },
  // { value: 'number', label: 'Text(Number)' },
  { value: 'email', label: 'Email' },
  { value: 'autocomplete', label: 'Autocomplete' },
  { value: 'date', label: 'Date' },
  { value: 'date-time', label: 'Date/Time' },
  { value: 'button', label: 'Button/Action' },
  { value: 'file', label: 'File Upload' },
  { value: 'checkbox', label: 'Checkbox' },
  { value: 'radio', label: 'Radio Button' },
  { value: 'dropdown', label: 'Dropdown Menu' },
  { value: 'textarea', label: 'Multiline Text Area' },
];
const mapandatoryFieldTypes = [
  { value: 'yes', label: 'Yes' },
  { value: 'optional', label: 'Optional' },
  { value: 'conditional', label: 'Conditional' },
];
const constant = {
  programs,
  vessels,
  init,
  transactioninit,
  genders,
  FormType,
  checkerLevels,
  availableTeams,
  sortOptions,
  TeamData,
  TeamDataForEdit,
  teamInitialValue,
  analystLevels,
  typeOfReport,
  fieldTypes,
  mapandatoryFieldTypes,
};
export default constant;
