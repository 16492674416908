// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Button,
  Grid,
  Typography,
  Box,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import PersonIcon from '@mui/icons-material/Person';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import ResetPassword from '../components/Login/ResetPassword';
import Otp from '../components/Login/Otp';
import { Assets } from '../assets/index';
import useAuth from '../hooks/useAuth';
import ExpiredPasswordForm from 'components/Login/ExpiredPasswordForm';

const PREFIX = 'Login';

const classes = {
  gifImg: `${PREFIX}-gifImg`,
  LoginBgColor: `${PREFIX}-LoginBgColor`,
  poweredText: `${PREFIX}-poweredText`,
  poweredByText: `${PREFIX}-poweredByText`,
  bgImg: `${PREFIX}-bgImg`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.gifImg}`]: {
    height: '72%',
    width: '67%',
    marginLeft: 'auto',
    marginRight: '2%',
    display: 'block',
  },

  [`&.${classes.LoginBgColor}`]: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.myComponent.login.bgColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.myComponent.login.bgColor,
      height: '100vh',
      width: '100%',
    },
  },

  [`& .${classes.poweredText}`]: {
    color: theme.palette.common.white,
    paddingRight: theme.spacing(6),
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.poweredByText}`]: {
    color: theme.palette.common.green,
    paddingRight: theme.spacing(6),
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.bgImg}`]: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${Assets.loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.green,
      backgroundImage: `url(${Assets.loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
}));

const validationSchema = Yup.object({
  accountId: Yup.string().required('Account Id is required'),
  username: Yup.string().required('Required!'),
  password: Yup.string().required('Password Required!'),
});

/**
 * @function Login
 * @description a component to render the UI of loginForm
 * @param {object} history - history object
 * @returns {React.ReactElement} return a component to render the UI of login
 * @example <Login history={history}/>
 */
const Login = ({ history }) => {
  const [currentPage, setCurrentPage] = useState('Login');
  const { login, isLoading, loginOtp, resetPassword, isLoggedIn,logout,passwordError,email } = useAuth();
  const loginForm = useFormik({
    initialValues: {
      accountId: '',
      username: '',
      password: '',
      hidden: true,
    },
    onSubmit: (values) => {
      login(values);
    },
    validationSchema,
  });

  useEffect(() => {
    if (isLoggedIn) {
      const url = localStorage.getItem('URL_TO_REDIRECT_AFTER_LOGIN');
      if (url?.length !== 0) {
        window.location.replace('', '', url);
      } else {
        history.push('/');
      }
      localStorage.removeItem('URL_TO_REDIRECT_AFTER_LOGIN');
    }
  }, [isLoggedIn]);
  useEffect(() => {
    if (passwordError) {
      setCurrentPage('reset')
    }
  }, [passwordError])

  const [values, setValues] = useState(loginForm.initialValues);
  // function to show password
  /**
   * @function handleClickShowPassword
   * @description function for showing the password
   * @returns {void}
   * @example handleClickShowPassword()
   */
  const handleClickShowPassword = () => {
    setValues({ ...values, hidden: !values.hidden });
  };
  /**
   * @function handleMouseDownPassword
   * @description function for handling the mouse down event on password
   * @param {object} - event object
   * @returns {void}
   * @example handleMouseDownPassword(event)
   */
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledGrid container direction="row" className={classes.LoginBgColor}>
      <Grid item xs={12} sm={5} order={{ xs: 2, sm: 1 }}>
        <Box mb={5} display="flex" justifyContent="center" alignItems="center">
          <img draggable="false" src={Assets.SAMSLogo} alt="SAMS" />
        </Box>
        <form onSubmit={loginForm.handleSubmit}>
          {isLoading === true && currentPage === 'Login' ? (
            <Box>
              <Grid
                container
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Grid direction="column" container item xs={10} sm={9}>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      autoComplete="organization"
                      type="text"
                      label=""
                      InputLabelProps={{ shrink: false }}
                      placeholder="Account Id"
                      {...loginForm.getFieldProps('accountId')}
                      error={Boolean(
                        loginForm.touched.accountId &&
                          loginForm.errors.accountId
                      )}
                      helperText={
                        loginForm.touched.accountId &&
                        loginForm.errors.accountId
                      }
                    />
                  </Box>
                  <Box mb={3}>
                    <TextField
                      fullWidth
                      autoComplete="username"
                      type="text"
                      label=""
                      InputLabelProps={{ shrink: false }}
                      placeholder="Username"
                      {...loginForm.getFieldProps('username')}
                      error={Boolean(
                        loginForm.touched.username && loginForm.errors.username
                      )}
                      helperText={
                        loginForm.touched.username && loginForm.errors.username
                      }
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              edge="end"
                            >
                              <PersonIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      label=""
                      InputLabelProps={{ shrink: false }}
                      type={values.hidden ? 'password' : 'text'}
                      {...loginForm.getFieldProps('password')}
                      name="password"
                      error={Boolean(
                        loginForm.touched.password && loginForm.errors.password
                      )}
                      helperText={
                        loginForm.touched.password && loginForm.errors.password
                      }
                      placeholder="Password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.hidden ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Button
                      color="primary"
                      type="submit"
                      variant="contained"
                      size="large"
                      sx={{
                        textTransform: 'capitalized',
                      }}
                    >
                      Login
                    </Button>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to="/login?view=forgot-password"
                    >
                      <Typography
                        onClick={() => setCurrentPage('forgotPassword')}
                        color="primary"
                        noWrap={true}
                      >
                        Forgot Password?
                      </Typography>
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : isLoading === true && passwordError ? (
            <ExpiredPasswordForm
              resetPassword={resetPassword}
              logout={logout}
              username={loginForm?.values?.username}
              accountId={loginForm?.values?.accountId}
              email={email}
              setCurrentPage={setCurrentPage}
            />
          ) : (
            isLoading === true && currentPage !== 'reset' &&(
              <ResetPassword
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                resetPassword={resetPassword}
              />
            )
          )}
        </form>
        {isLoading === false && <Otp loginOtp={loginOtp} />}
        <Typography variant="body1" noWrap={true}>
          <Box
            component="span"
            mt={10}
            fontWeight="fontWeightMedium"
            className={classes.poweredByText}
          >
            © Solytics Partners LLC 2022. All rights reserved.
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7} order={{ xs: 1, sm: 2 }}>
        <Box
          component="div"
          className={classes.bgImg}
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <a
              href="https://solytics-partners.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                draggable="false"
                src={Assets.solyticsPartnersLogo}
                alt="SAMS"
                style={{ height: 60, width: 150 }}
              />
            </a>
          </Box>
          <img
            draggable="false"
            className={classes.gifImg}
            src={Assets.bggif}
            alt=""
          />
          <Typography variant="subtitle1" noWrap={true}>
            <Box
              mt={18}
              fontWeight="fontWeightMedium"
              className={classes.poweredText}
            >
              © Solytics Partners LLC 2022. All rights reserved.
            </Box>
          </Typography>
        </Box>
      </Grid>
    </StyledGrid>
  );
};
export default Login;
Login.propTypes = {
  history: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
