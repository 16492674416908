// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Stack,
  Button,
  Dialog,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  InputAdornment,
  Grid,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomTextField from '../Common/CustomTextField';
import { Constants } from '../../const/index';

const { Regex } = Constants;

const validationSchema = Yup.object().shape({
  old_password: Yup.string().required('Required!'),
  new_password: Yup.string()
    .required('Please Enter your password')
    .matches(
      Regex.PASSWORD,
      'Must Contain 14 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
    ),
  confirmPassword: Yup.string()
    .required('Required!')
    .oneOf([Yup.ref('new_password')], 'Passwords must match'),
});
/**
@function ExpiredPasswordForm
@description - A form to change the current password
@param {function} resetPassword - function to patch the changed value
@returns {JSX} ExpiredPasswordForm UI
@example <ExpiredPasswordForm  />
 */
const ExpiredPasswordForm = ({
  resetPassword,
  logout,
  username,
  accountId,
  email,
  setCurrentPage,
}) => {
  const passwordForm = useFormik({
    initialValues: {
      old_password: '',
      new_password: '',
      confirmPassword: '',
      accountId: accountId,
      username: username
    },
    validationSchema,
    onSubmit: (values) => {
      resetPassword({ ...values, email }).then(() => {
        setTimeout(() => {
          setCurrentPage('Login');
        }, 800);
      });
      passwordForm.resetForm();
    },
  });
  const [visibleFields, setVisibleFields] = React.useState({
    currentValues: true,
    newValue: true,
    confirmValues: true,
  });
  // function to show password
  const handleClickNewShowPassword = () => {
    setVisibleFields({ ...visibleFields, newValue: !visibleFields.newValue });
  };
  // function to show current password
  const handleClickCurrentShowPassword = () => {
    setVisibleFields({
      ...visibleFields,
      currentValues: !visibleFields.currentValues,
    });
  };
  // function to confirm password
  const handleClickConfirmShowPassword = () => {
    setVisibleFields({
      ...visibleFields,
      confirmValues: !visibleFields.confirmValues,
    });
  };

  return (
    <Box>
      <Grid
        container
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Grid direction="column" container item xs={10} sm={9}>
          <Box mb={3}>
            <CustomTextField
              id="txt_layout_change_password_current_password"
              name="old_password"
              autoComplete="new-password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.currentValues ? 'password' : 'text'}
              placeholder="Current Password"
              {...passwordForm.getFieldProps('old_password')}
              errorMessege={
                passwordForm.touched.old_password &&
                passwordForm.errors.old_password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickCurrentShowPassword}
                      edge="end"
                    >
                      {visibleFields.currentValues ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={3}>
            <CustomTextField
              id="txt_layout_change_password_new_password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.newValue ? 'password' : 'text'}
              name="new_password"
              autoComplete="off"
              placeholder="New Password"
              {...passwordForm.getFieldProps('new_password')}
              errorMessege={
                passwordForm.touched.new_password &&
                passwordForm.errors.new_password
              }
              helperText={
                passwordForm.touched.new_password &&
                passwordForm.errors.new_password
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickNewShowPassword} edge="end">
                      {visibleFields.newValue ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box mb={3}>
            <CustomTextField
              id="txt_layout_change_password_conform_password"
              InputLabelProps={{ shrink: false }}
              type={visibleFields.confirmValues ? 'password' : 'text'}
              name="confirmPassword"
              autoComplete="off"
              placeholder="Confirm Password"
              {...passwordForm.getFieldProps('confirmPassword')}
              errorMessege={
                passwordForm.touched.confirmPassword &&
                passwordForm.errors.confirmPassword
              }
              helperText={
                passwordForm.touched.confirmPassword &&
                passwordForm.errors.confirmPassword
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickConfirmShowPassword}
                      edge="end"
                    >
                      {visibleFields.confirmValues ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Button
              variant="contained"
              color="primary"
              onClick={passwordForm.handleSubmit}
            >
              Submit
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default React.memo(ExpiredPasswordForm);
ExpiredPasswordForm.propTypes = {
  resetPassword: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
};
