// @ts-nocheck
import React from 'react';
import { TextField, Button, Grid, Box, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Keycloak from 'keycloak-js';
import { useFormik } from 'formik';
import { object, string } from 'yup';

import { initializeAxiosForKeycloak } from '../utils/ApiKeycloak';
// import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';
// import LoginCarousel from './LoginCarousel';
import { Utils } from '../utils';
import { Assets } from '../assets/index';

const PREFIX = 'Login';

const classes = {
  gifImg: `${PREFIX}-gifImg`,
  LoginBgColor: `${PREFIX}-LoginBgColor`,
  poweredText: `${PREFIX}-poweredText`,
  poweredByText: `${PREFIX}-poweredByText`,
  bgImg: `${PREFIX}-bgImg`,
};

const StyledGrid = styled(Grid)(({ theme }) => ({
  [`& .${classes.gifImg}`]: {
    height: '72%',
    width: '67%',
    marginLeft: 'auto',
    marginRight: '2%',
    display: 'block',
  },

  [`&.${classes.LoginBgColor}`]: {
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.myComponent.login.bgColor,
      justifyContent: 'center',
      alignItems: 'center',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.myComponent.login.bgColor,
      height: '100vh',
      width: '100%',
    },
  },

  [`& .${classes.poweredText}`]: {
    color: theme.palette.common.white,
    paddingRight: theme.spacing(6),
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.poweredByText}`]: {
    color: theme.palette.common.green,
    paddingRight: theme.spacing(6),
    flexGrow: 1,
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },

  [`& .${classes.bgImg}`]: {
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url(${Assets.loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      height: '100vh',
      width: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      backgroundColor: theme.palette.common.green,
      backgroundImage: `url(${Assets.loginBackground})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
  },
}));
const Organization = () => {
  const { CoreUtils } = Utils;
  const formForgetValidationSchema = object({
    realmId: string().required('Required'),
  });
  const organizationForm = useFormik({
    initialValues: {
      realmId: '',
    },
    enableReinitialize: true,
    validationSchema: formForgetValidationSchema,
    onSubmit: (values) => {
      localStorage.setItem('REALM_ID', values?.realmId);

      const initOptions = {
        url: process.env.REACT_APP_KEYCLOACK_URL,
        realm: localStorage.getItem('REALM_ID'),
        clientId: 'sams-local',
        onLoad: 'check-sso', // check-sso | login-required
        KeycloakResponseType: 'code',
      };
      const keycloakConfig = new Keycloak(initOptions);
      window.KEYCLOAK_CONFIG = keycloakConfig;
      CoreUtils.initKeycloak().then(() => {
        initializeAxiosForKeycloak();
      });
    },
  });

  return (
    <StyledGrid container direction="row" className={classes.LoginBgColor}>
      <Grid item xs={12} sm={5} order={{ xs: 2, sm: 1 }}>
        <Box mb={5} display="flex" justifyContent="center" alignItems="center">
          <img draggable="false" src={Assets.SAMSLogo} alt="SAMS" />
        </Box>
        <Stack direction="column" spacing={2}>
          <Box ml={5}>
            <TextField
              required
              {...organizationForm?.getFieldProps('realmId')}
              // variant="filled"
              label="Enter account Id"
              helperText={CoreUtils.getErrorHelperText(
                organizationForm?.errors?.realmId,
                organizationForm?.touched?.realmId
              )}
              error={Boolean(
                CoreUtils.getErrorHelperText(
                  organizationForm?.errors?.realmId,
                  organizationForm?.touched?.realmId
                )
              )}
            />
          </Box>
          {/* <Stack justifyContent="center" alignItems="center"> */}
          <Box
            display="flex"
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              color="primary"
              type="submit"
              variant="contained"
              size="large"
              sx={{
                textTransform: 'capitalized',
              }}
              onClick={organizationForm.handleSubmit}
            >
              Submit
            </Button>
          </Box>
          {/* </Stack> */}
        </Stack>
        <Typography variant="body1" noWrap={true}>
          <Box
            component="span"
            mt={10}
            fontWeight="fontWeightMedium"
            className={classes.poweredByText}
          >
            © Solytics Partners LLC 2022. All rights reserved.
          </Box>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7} order={{ xs: 1, sm: 2 }}>
        <Box
          component="div"
          className={classes.bgImg}
          justifyContent="center"
          alignItems="center"
        >
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <a
              href="https://solytics-partners.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                draggable="false"
                src={Assets.solyticsPartnersLogo}
                alt="SAMS"
                style={{ height: 60, width: 150 }}
              />
            </a>
          </Box>
          <img
            draggable="false"
            className={classes.gifImg}
            src={Assets.bggif}
            alt=""
          />
          <Typography variant="subtitle1" noWrap={true}>
            <Box
              mt={18}
              fontWeight="fontWeightMedium"
              className={classes.poweredText}
            >
              © Solytics Partners LLC 2022. All rights reserved.
            </Box>
          </Typography>
        </Box>
      </Grid>
    </StyledGrid>
  );
};

export default Organization;
